import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',

    '& > *:nth-child(2)': {
      flex: 1,
      overflowY: 'auto'
    }
  }
}

function AppBarLayout (props) {
  const {
    appBarRenderer,
    children,
    classes,
    onOpenDrawer: onToggleDrawer
  } = props

  return (
    <div className={classes.root}>
      {appBarRenderer({ onToggleDrawer })}
      {children}
    </div>
  )
}

export default withStyles(styles)(AppBarLayout)
