import React from 'react'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import { formatTime } from '../../util/time'

const styles = (theme) => ({
  root: {
    padding: 8
  },
  paper: {
    padding: 16,
    marginBottom: 8,
    background: theme.palette.secondary.main,
    color: '#fff'
  },
  results: {
    width: '100%',
    fontSize: '15px',
    borderCollapse: 'collapse',
    '& tr > td:last-child': {
      textAlign: 'right'
    }
  },
  bold: {
    fontWeight: 700
  },
  spacing: {
    height: 8
  },
  spacingLine: {
    height: 8,
    '& > td': {
      borderBottom: '1px solid #fff'
    }
  }
})

function getPoints (time, groupSize) {
  if (groupSize === 9) {
    if (time <= 55) {
      return 4
    }
    if (time <= 60) {
      return 3
    }
    if (time <= 65) {
      return 2
    }
    if (time <= 75) {
      return 1
    }
    return 0
  } else if (groupSize === 6) {
    if (time <= 50) {
      return 4
    }
    if (time <= 55) {
      return 3
    }
    if (time <= 60) {
      return 2
    }
    if (time <= 70) {
      return 1
    }
    return 0
  }
}

function Results (props) {
  const {
    classes,
    time,
    runnerTimes,
    lengths,
    groupSize,
    ...other
  } = props

  return (
    <div {...other} className={classes.root}>
      <Paper className={classes.paper}>
        <table className={classes.results}>
          <tbody>
            <tr><td>Zeit:</td><td colSpan={2}>{formatTime(time)}</td></tr>
            <tr className={classes.spacingLine}><td colSpan={3} /></tr>
            <tr className={classes.spacing} />
            <tr className={classes.bold}><td>Punkte:</td><td colSpan={2}>{getPoints(time, groupSize)}</td></tr>
          </tbody>
        </table>
      </Paper>
    </div>
  )
}

export default withStyles(styles)(Results)
