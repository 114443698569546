import { useSnackbar } from "material-ui-snackbar-provider";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import useTimer from "../../../hooks/useTimer";
import TimerComponent from "../components/Timer";
import { getMaximumSeconds } from "../util";
import * as actions from "../actions";

export default function Timer(props) {
  const maximumSeconds = useSelector((state) =>
    getMaximumSeconds(state.groupManagement.members.filter((m) => m.isInGroup))
  );
  const {
    startTimer,
    resumeTimer,
    stopTimer,
    resetTimer,
    unresetTimer,
    time,
    active,
  } = useTimer((state) => state.partB.timer, actions);
  const snackbar = useSnackbar();

  const handleToggleTimer = useCallback(() => {
    if (active) {
      stopTimer();
      snackbar.showMessage("Stoppuhr angehalten", "Rückgängig", () => {
        resumeTimer();
      });
    } else if (time > 0) {
      resetTimer();
      snackbar.showMessage("Stoppuhr zurückgesetzt", "Rückgängig", () => {
        unresetTimer();
      });
    } else {
      startTimer();
      snackbar.showMessage("Stoppuhr gestartet");
    }
  }, [
    active,
    resetTimer,
    resumeTimer,
    snackbar,
    startTimer,
    stopTimer,
    time,
    unresetTimer,
  ]);

  return (
    <TimerComponent
      {...props}
      seconds={Math.round(time / 1000)} // gemäß Absatz 5.6 der Wettbewerbsordnung 2013 werden die Sekunden kaufmännisch gerundet
      maximumSeconds={maximumSeconds}
      onClick={handleToggleTimer}
    />
  );
}
