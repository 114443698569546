export const TOGGLE_MISTAKE = "PartA/TOGGLE_MISTAKE";
export const START_TIMER = "PartA/START_TIMER";
export const STOP_TIMER = "PartA/STOP_TIMER";
export const RESUME_TIMER = "PartA/RESUME_TIMER";
export const RESET_TIMER = "PartA/RESET_TIMER";
export const UNRESET_TIMER = "PartA/UNRESET_TIMER";
export const START_KNOTS_TIMER = "PartA/START_KNOTS_TIMER";
export const STOP_KNOTS_TIMER = "PartA/STOP_KNOTS_TIMER";
export const RESUME_KNOTS_TIMER = "PartA/RESUME_KNOTS_TIMER";
export const RESET_KNOTS_TIMER = "PartA/RESET_KNOTS_TIMER";
export const UNRESET_KNOTS_TIMER = "PartA/UNRESET_KNOTS_TIMER";
export const RESET_RESULTS = "PartA/RESET_RESULTS";
export const MERGE_RESULTS = "PartA/MERGE_RESULTS";

export function toggleMistake(role, mistake, times) {
  return { type: TOGGLE_MISTAKE, payload: { role, mistake, times } };
}

export function startTimer(time = new Date()) {
  return { type: START_TIMER, payload: time.toISOString() };
}

export function stopTimer(time = new Date()) {
  return { type: STOP_TIMER, payload: time.toISOString() };
}

export function resumeTimer() {
  return { type: RESUME_TIMER };
}

export function resetTimer() {
  return { type: RESET_TIMER };
}

export function unresetTimer() {
  return { type: UNRESET_TIMER };
}

export function startKnotsTimer(time = new Date()) {
  return { type: START_KNOTS_TIMER, payload: time.toISOString() };
}

export function stopKnotsTimer(time = new Date()) {
  return { type: STOP_KNOTS_TIMER, payload: time.toISOString() };
}

export function resumeKnotsTimer() {
  return { type: RESUME_KNOTS_TIMER };
}

export function resetKnotsTimer() {
  return { type: RESET_KNOTS_TIMER };
}

export function unresetKnotsTimer() {
  return { type: UNRESET_KNOTS_TIMER };
}

export function resetResults() {
  return { type: RESET_RESULTS };
}

export function mergeResults(results) {
  return { type: MERGE_RESULTS, payload: results };
}
