import { connect } from 'react-redux'
import Shots from '../components/Shots'
import { setShot } from '../actions'

export default connect(
  (state) => ({
    shots: state.shotPut.shots.slice(0, state.settings.lspGroupSize),
    groupSize: state.settings.lspGroupSize
  }),
  {
    onShotChanged: setShot
  })(Shots)
