import { SET_WATERSOURCE, SET_TIME_SORTING, SET_HIDE_CLOTH_ERRORS, SET_HIDE_FAST_COUPLING_ERRORS, SET_LSP_GROUP_SIZE } from './actions'

// either 'og' (Offenes Gewässer) or 'uh' (Unterflurhydrant)
export function waterSource (state = 'uh', action) {
  switch (action.type) {
    case SET_WATERSOURCE:
      return action.payload
    default:
      return state
  }
}

export function timeSorting (state = true, action) {
  switch (action.type) {
    case SET_TIME_SORTING:
      return action.payload
    default:
      return state
  }
}

export function hideClothErrors (state = false, action) {
  switch (action.type) {
    case SET_HIDE_CLOTH_ERRORS:
      return action.payload
    default:
      return state
  }
}

export function hideFastCouplingErrors (state = true, action) {
  switch (action.type) {
    case SET_HIDE_FAST_COUPLING_ERRORS:
      return action.payload
    default:
      return state
  }
}

// either 9 (group) or 6 (squadron)
export function lspGroupSize (state = 9, action) {
  switch (action.type) {
    case SET_LSP_GROUP_SIZE:
      return action.payload
    default:
      return state
  }
}
