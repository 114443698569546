/* global localStorage */
import { combineReducers } from 'redux'
import * as reducers from './reducers'

export { default } from './containers/Settings'

const combinedReducer = combineReducers(reducers)
export const reducer = function (state = JSON.parse(localStorage.getItem('settings')) || {}, action) {
  return combinedReducer(state, action)
}
