import * as actions from "./actions";

const defaultMistakes = {
  gf: {},
  me: {},
  ma: {},
  at: {},
  atf: {},
  atm: {},
  wt: {},
  wtf: {},
  wtm: {},
  st: {},
  stf: {},
  stm: {},
};

export function mistakes(state = defaultMistakes, action) {
  switch (action.type) {
    case actions.TOGGLE_MISTAKE: {
      const { mistake, role, times } = action.payload;
      return {
        ...state,
        [role]: {
          ...state[role],
          [mistake]: times,
        },
      };
    }
    case actions.MERGE_RESULTS: {
      const mistakes = { ...state };
      for (const role of Object.keys(mistakes)) {
        mistakes[role] = { ...mistakes[role], ...action.payload[role] };
      }
      return mistakes;
    }
    case actions.RESET_RESULTS:
      return defaultMistakes;
    default:
      return state;
  }
}

export function timer(
  state = {
    active: false,
    startedAt: null,
    stoppedAt: null,
    time: null,
  },
  action
) {
  switch (action.type) {
    case actions.START_TIMER:
      return {
        ...state,
        active: true,
        startedAt: action.payload,
        stoppedAt: null,
        time: null,
      };
    case actions.STOP_TIMER:
      return {
        ...state,
        active: false,
        stoppedAt: action.payload,
        time: Date.parse(action.payload) - Date.parse(state.startedAt),
      };
    case actions.RESUME_TIMER:
      return {
        ...state,
        active: true,
        stoppedAt: null,
      };
    case actions.RESET_TIMER:
      return {
        ...state,
        previousStartedAt: state.startedAt,
        previousStoppedAt: state.stoppedAt,
        startedAt: null,
        stoppedAt: null,
      };
    case actions.UNRESET_TIMER:
      return {
        ...state,
        startedAt: state.previousStartedAt,
        stoppedAt: state.previousStoppedAt,
      };
    case actions.RESET_RESULTS:
      return {
        ...state,
        active: false,
        startedAt: null,
        stoppedAt: null,
        time: null,
      };
    default:
      return state;
  }
}

export function knotsTimer(
  state = {
    active: false,
    startedAt: null,
    stoppedAt: null,
    time: null,
  },
  action
) {
  switch (action.type) {
    case actions.START_KNOTS_TIMER:
      return {
        ...state,
        active: true,
        startedAt: action.payload,
        stoppedAt: null,
      };
    case actions.STOP_KNOTS_TIMER:
      return {
        ...state,
        active: false,
        stoppedAt: action.payload,
        time: Date.parse(action.payload) - Date.parse(state.startedAt),
      };
    case actions.RESUME_KNOTS_TIMER:
      return {
        ...state,
        active: true,
        stoppedAt: null,
      };
    case actions.RESET_KNOTS_TIMER:
      return {
        ...state,
        previousStartedAt: state.startedAt,
        previousStoppedAt: state.stoppedAt,
        active: false,
        startedAt: null,
        stoppedAt: null,
      };
    case actions.UNRESET_KNOTS_TIMER:
      return {
        ...state,
        startedAt: state.previousStartedAt,
        stoppedAt: state.previousStoppedAt,
      };
    case actions.RESET_RESULTS:
      return {
        ...state,
        active: false,
        startedAt: null,
        stoppedAt: null,
        time: null,
      };
    default:
      return state;
  }
}
