import React from 'react'
import { BrowserQRCodeReader } from '@zxing/library'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import BackIcon from 'mdi-material-ui/ArrowLeft'
import withStyles from '@material-ui/core/styles/withStyles'
import AppBarLayout from './AppBarLayout'
import AppBar from './AppBar'

const styles = {
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  video: {
    objectFit: 'cover',
    overflow: 'hidden',
    flex: 1,
    width: '100%',
    borderRadius: 0.5 // https://stackoverflow.com/a/49659136
  },
  progress: {
    margin: '-8px 24px 24px'
  },
  help: {
    margin: 24,
    textAlign: 'center'
  }
}

class DataReceiver extends React.Component {
  state = {
    frame: '',
    result: []
  }

  handleScan = (data) => {
    try {
      if (data.length < 4) return

      const frame = parseInt(data.substr(0, 2), 10)
      const count = parseInt(data.substr(2, 2), 10)
      const content = data.substr(4)
      let result = [...this.state.result]
      if (result.length !== count) {
        // new code
        result = [...Array(count)]
      }
      result[frame] = content
      this.setState({ result })
      if (result.every((c) => c != null)) {
        this.props.onDataReceived(JSON.parse(result.join('')))
        setTimeout(() => this.stopScan())
      } else {
        this.continueScan()
      }
    } catch (e) {
      this.setState({ result: [] })
      this.continueScan()
    }
  }

  setVideoElement = (ref) => {
    this._video = ref
  }

  componentDidMount() {
    // TODO allow changing the camera
    this._scanner = new BrowserQRCodeReader(250)
    this._scanner.decodeFromInputVideoDevice(undefined, this._video)
      .then((result) => {
        this.handleScan(result.getText())
      })
  }

  continueScan() {
    this._scanner.decodeOnceWithDelay(
      (result) => this.handleScan(result.getText()),
      () => this.continueScan()
    )
  }

  stopScan() {
    if (this._scanner) {
      this._scanner.reset()
      this._scanner = null
    }
  }

  componentWillUnmount() {
    this.stopScan()
  }

  handleClose = () => {
    this.props.onClose()
    setTimeout(() => this.stopScan())    
  }

  render () {
    const {
      classes
    } = this.props

    const {
      result
    } = this.state

    return (
      <AppBarLayout
        appBarRenderer={() => (
          <AppBar
            title='Daten empfangen'
            leftIcon={BackIcon}
            onButtonClick={this.handleClose}
          />
        )}
      >
        <div className={classes.content}>
          <Typography className={classes.help}>
            Die Daten werden optisch übermittelt. Starte die Datenübertragung auf dem Sendegerät und richte die Kamera auf die dort angezeigte Grafik.
          </Typography>
          <LinearProgress
            variant='determinate'
            value={result.length === 0 ? 0 : result.filter((c) => c != null).length * 100 / result.length}
            className={classes.progress}
          />
          <video
            ref={this.setVideoElement}
            className={classes.video}
          />
        </div>
      </AppBarLayout>
    )
  }
}

export default withStyles(styles)(DataReceiver)
