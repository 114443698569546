import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { SnackbarProvider, withSnackbar } from 'material-ui-snackbar-provider'
import { AppBarLayout, AppBar } from '../AppFrame'
import BottomNavigation from './components/BottomNavigation'
import Timer from './containers/Timer'
import Results from './containers/Results'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  navigation: {
    width: '100%',
    zIndex: 101
  },
  menuButton: {
    marginRight: -12
  },
  snackbar: {
    marginBottom: 56,
    zIndex: 100
  }
}

class SpeedExercise extends PureComponent {
  state = {
    activePage: 'timer'
  }

  handleChangePage = (event, value) => {
    this.setState({ activePage: value })
  }

  render () {
    const {
      classes,
      className,
      onOpenDrawer,
      dispatch,
      mistakes,
      ...other
    } = this.props

    const {
      activePage,
    } = this.state

    return (
      <SnackbarProvider
        ButtonProps={{ color: 'primary' }}
        SnackbarProps={{ autoHideDuration: 4000, classes: { root: classes.snackbar } }}
      >
        <AppBarLayout
          appBarRenderer={(props) => (
            <AppBar
              {...props}
              title='Schnelligkeitsübung'
              onButtonClick={onOpenDrawer}
            />
          )}
        >
          <div className={classes.root} {...other}>
            <Timer
              style={{
                display: activePage === 'timer' ? 'flex' : 'none',
                flex: 1
              }}
            />
            <Results
              style={{
                display: activePage === 'results' ? 'block' : 'none',
                flex: 1,
                overflow: 'auto'
              }}
            />

            <BottomNavigation
              classes={{ root: classes.navigation }}
              activePage={activePage}
              onChangePage={this.handleChangePage}
            />
          </div>
        </AppBarLayout>
      </SnackbarProvider>
    )
  }
}

export default withStyles(styles)(withSnackbar()(SpeedExercise))
