import { connect } from "react-redux";
import Results from "../components/Results";
import { positionsToLengths } from "../util";

export default connect(
  (state) => ({
    time:
      state.relayRace.timer.time != null
        ? Math.round(state.relayRace.timer.time / 1000)
        : null,
    runnerTimes: state.relayRace.runnerTimes,
    lengths: positionsToLengths(
      state.settings.lspGroupSize === 6
        ? state.relayRace.squadronPositions
        : state.relayRace.positions,
      state.settings.lspGroupSize
    ),
    groupSize: state.settings.lspGroupSize,
  }),
  () => ({})
)(Results);
