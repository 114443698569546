import { connect } from "react-redux";
import Results from "../components/Results";

export default connect(
  (state) => ({
    time: state.speedExercise.timer.time
      ? Math.round(state.speedExercise.timer.time / 1000)
      : null,
    groupSize: state.settings.lspGroupSize,
  }),
  () => ({})
)(Results);
