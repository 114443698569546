export function positionsToLengths (positions, groupSize) {
  const totalLength = groupSize === 9 ? 1500 : 1000
  const lengths = []
  let current = 0
  for (const { position } of positions.slice(1)) {
    lengths.push({
      start: current,
      length: Math.max(0, position - current),
      name: positions[lengths.length].name
    })
    current = position
  }
  lengths.push({
    start: current,
    length: Math.max(0, totalLength - current),
    name: positions[positions.length - 1].name
  })
  return lengths
}
