import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Transition from 'react-transition-group/Transition'

const styles = {
  entering: {
    opacity: 1
  },
  entered: {
    opacity: 1
  }
}

const transitionStyles = {
  initial: {
    transition: 'all 225ms cubic-bezier(0.0, 0.0, 0.2, 1)',
    transform: 'translate(0, 56px)'
  },
  entering: {
    transform: 'translate(0, 0px)',
    transition: 'all 225ms cubic-bezier(0.0, 0.0, 0.2, 1)',
  },
  exiting: {
    transition: 'all 195ms cubic-bezier(0.4, 0.0, 1, 1)',
    transform: 'translate(0, 56px)'
  },
  exited: {
    transition: 'all 225ms cubic-bezier(0.0, 0.0, 0.2, 1)',
    transform: 'translate(0, 56px)'
  }
}

function setStyles (styles, node) {
  for (const style of Object.keys(styles)) {
    node.style[style] = styles[style]
  }
}

class Slide extends React.Component {
  handleEnter = (node) => {
    if (this.props.onEnter) {
      this.props.onEnter(node)
    }
  }

  handleEntering = (node) => {
    setStyles(transitionStyles.entering, node)
    if (this.props.onEntering) {
      this.props.onEntering(node)
    }
  }

  handleExit = (node) => {
    setStyles(transitionStyles.exiting, node)
    if (this.props.onExit) {
      this.props.onExit(node)
    }
  }

  handleExited = (node) => {
    setStyles(transitionStyles.exited, node)
    if (this.props.onExited) {
      this.props.onExited(node)
    }
  }

  render () {
    const { children, onEnter, onEntering, onExit, onExiting, style: styleProp, ...other } = this.props

    const style = {
      ...styleProp,
      ...(React.isValidElement(children) ? children.props.style : {}),
    }

    return (
      <Transition
        {...other}
        timeout={{exit: 225, enter: 195}}
        onEnter={this.handleEnter}
        onEntering={this.handleEntering}
        onExit={this.handleExit}
        onExited={this.handleExited}
        appear
      >
        {(state, childProps) => React.cloneElement(children, {
          style: {
            opacity: 0,
            willChange: 'opacity',
            ...transitionStyles.initial,
            ...styles[state],
            ...style
          },
          ...childProps
        })}
      </Transition>
    )
  }
}

export default ({ children, ...other }) => (
  <Dialog
    {...other}
    fullScreen
    TransitionComponent={Slide}
    hideBackdrop
  >
    {children}
  </Dialog>
)
