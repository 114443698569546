import React from "react";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import { formatTime } from "../../util/time";
import { getTime } from "../util";
import { getTotalErrorPoints } from "./RatingAccordion/RatingAccordion";
import RunnerPanel from "./RatingAccordion/RunnerPanel";
import * as errors from "../errors";

const styles = (theme) => ({
  root: {
    padding: 8,
  },
  paper: {
    padding: 16,
    marginBottom: 8,
    background: theme.palette.secondary.main,
    color: "#fff",
  },
  results: {
    width: "100%",
    fontSize: "15px",
    borderCollapse: "collapse",
    "& tr > td:last-child": {
      textAlign: "right",
    },
  },
  bold: {
    fontWeight: 700,
  },
  spacing: {
    height: 8,
  },
  spacingLine: {
    height: 8,
    "& > td": {
      borderBottom: "1px solid #fff",
    },
  },
});

function getCheckedMistakes(checkedMistakes, { roles, errors }) {
  const isChecked = (error) =>
    roles.some((role) => checkedMistakes[role][error.id] > 0);

  const checkedMistakesThisSheet = [];
  for (const error of errors) {
    if (isChecked(error)) {
      checkedMistakesThisSheet.push(error);
    } else if (
      !Number.isInteger(error.points) &&
      error.points.some(isChecked)
    ) {
      const checkedSubErrors = error.points.filter((subError) =>
        isChecked(subError)
      );
      if (checkedSubErrors.length > 0) {
        checkedMistakesThisSheet.push({
          ...error,
          points: checkedSubErrors,
        });
      }
    }
  }

  return { roles, errors: checkedMistakesThisSheet };
}

function Results(props) {
  const { classes, time, knotsTime, mistakes, waterSource, ...other } = props;

  const errorPoints = getTotalErrorPoints(mistakes);
  const maximumTime = getTime(waterSource);
  const totalPoints =
    1000 -
    errorPoints -
    Math.max(0, (time ?? 0) - maximumTime) -
    (knotsTime ?? 0);

  const sheets = [
    {
      sheet: "gfMe",
      title: "Gruppenführer/Melder",
    },
    {
      sheet: "ma",
      title: "Maschinist",
    },
    {
      sheet: "at",
      title: "Angriffstrupp",
    },
    {
      sheet: "wt",
      title: "Wassertrupp",
    },
    {
      sheet: "st",
      title: "Schlauchtrupp",
    },
  ];

  return (
    <div {...other} className={classes.root}>
      <Paper className={classes.paper}>
        <table className={classes.results}>
          <tbody>
            <tr>
              <td>Zeitvorgabe:</td>
              <td>{formatTime(maximumTime)}</td>
            </tr>
            <tr>
              <td>Vorgabepunkte:</td>
              <td>1000</td>
            </tr>
            <tr className={classes.spacing} />
            <tr>
              <td>Zeit:</td>
              <td>{time > 0 ? formatTime(time) : "—"}</td>
            </tr>
            <tr>
              <td>Knoten und Stiche:</td>
              <td>{knotsTime > 0 ? formatTime(knotsTime) : "—"}</td>
            </tr>
            <tr className={classes.spacing} />
            <tr>
              <td>Zeitüberschreitung:</td>
              <td>
                {time > 0 ? formatTime(Math.max(0, time - maximumTime)) : "—"}
              </td>
            </tr>
            <tr>
              <td>Fehlerpunkte:</td>
              <td>{errorPoints}</td>
            </tr>
            <tr className={classes.spacingLine}>
              <td colSpan={2} />
            </tr>
            <tr className={classes.spacing} />
            <tr className={classes.bold}>
              <td>Summe A-Teil:</td>
              <td>{totalPoints != null ? totalPoints : "—"}</td>
            </tr>
          </tbody>
        </table>
      </Paper>

      <div>
        {sheets
          .reduce((sheets, sheet) => {
            const checkedMistakesThisSheet = getCheckedMistakes(
              mistakes,
              errors[waterSource][sheet.sheet]
            );
            if (checkedMistakesThisSheet.errors.length === 0) {
              return sheets;
            }
            return [
              ...sheets,
              {
                ...sheet,
                checkedMistakesThisSheet,
              },
            ];
          }, [])
          .map(({ sheet, title, checkedMistakesThisSheet }) => (
            <RunnerPanel
              key={sheet}
              sheet={sheet}
              title={title}
              defaultExpanded
              mistakes={checkedMistakesThisSheet}
              checkedMistakes={mistakes}
              waterSource={waterSource}
            />
          ))}
      </div>
    </div>
  );
}

export default withStyles(styles)(Results);
