import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import PointsButton from './PointsButton'
import * as roles from '../roles'

const styles = (theme) => ({
  mistakes: {
    width: '100%',
    fontSize: theme.typography.pxToRem(15),
    borderCollapse: 'collapse',
    '& tr > td:first-child': {
      paddingLeft: 24,
      paddingRight: 8
    },
    '& tr$nestedMistake > td:first-child': {
      paddingLeft: 40
    },
    '& tr > td:last-child': {
      paddingRight: 24
    }
  },
  nestedMistake: {
  },
  multiple: {
    opacity: 0.54,
    fontSize: theme.typography.pxToRem(13),
    paddingRight: 8
  },
  points: {
    textAlign: 'center'
  }
})

function ErrorsTable (props) {
  const {
    checkedMistakes,
    classes,
    mistakes,
    onToggleMistake,
    hideClothErrors,
    hideFastCouplingErrors
  } = props

  return (
    <table className={classes.mistakes}>
      <thead>
        <tr>
          <td />
          <td />
          {mistakes.roles.map((role) => (
            <td className={classes.points} key={role}>
              {roles[role].short}
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {mistakes.errors
          .filter((mistake) => !hideClothErrors || mistake.type !== 'clothes')
          .filter((mistake) => !hideFastCouplingErrors || mistake.type !== 'fastCoupling')
          .map((mistake) => (
            <React.Fragment key={mistake.id}>
              <tr className={classes.mistake}>
                <td colSpan={mistake.multiple ? 1 : 2}>{mistake.description}</td>
                {mistake.multiple && (<td className={classes.multiple}>{mistake.multiple}</td>)}

                {mistakes.roles.map((role) =>
                  Number.isInteger(mistake.points) && (mistake.appliesTo == null || mistake.appliesTo.indexOf(role) >= 0) ? (
                    <td key={role} className={classes.points}>
                      <PointsButton
                        points={mistake.points}
                        multiple={mistake.multiple != null}
                        checked={checkedMistakes[role][mistake.id] || 0}
                        role={role}
                        errorId={mistake.id}
                        onChange={onToggleMistake}
                        disabled={onToggleMistake == null}
                      />
                    </td>
                  ) : (<td key={role} />)
                )}
              </tr>
              {!Number.isInteger(mistake.points) && mistake.points.map((subMistake) => (
                <tr key={subMistake.id} className={classes.nestedMistake}>
                  <td colSpan={subMistake.multiple ? 1 : 2}>{subMistake.description}</td>
                  {subMistake.multiple && (<td className={classes.multiple}>{subMistake.multiple}</td>)}
                  {mistakes.roles.map((role) => {
                    const parentMistakeApplies = mistake.appliesTo == null || mistake.appliesTo.indexOf(role) >= 0
                    const subMistakeApplies = parentMistakeApplies || (subMistake.appliesTo != null && subMistake.appliesTo.indexOf(role) >= 0)

                    return subMistakeApplies ? (
                      <td key={role} className={classes.points}>
                        <PointsButton
                          points={subMistake.points}
                          multiple={subMistake.multiple != null}
                          checked={checkedMistakes[role][subMistake.id] || 0}
                          role={role}
                          errorId={subMistake.id}
                          onChange={onToggleMistake}
                          disabled={onToggleMistake == null}
                        />
                      </td>
                    ) : (<td key={role} />)
                  })}
                </tr>
              ))}
            </React.Fragment>
          ))
        }
      </tbody>
    </table>
  )
}

ErrorsTable.propTypes = {
  checkedMistakes: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  mistakes: PropTypes.object.isRequired,
  onToggleMistake: PropTypes.func
}

export default withStyles(styles)(ErrorsTable)
