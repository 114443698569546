import { error, multiError, getErrorById, filterPhaseErrors } from './util'
export { getErrorById }

const clothes = error(
  'Mängel in der persönlichen Ausrüstung', [
    error('kein DJF-Übungsanzug', 10),
    error('kein DJF-Schutzhelm', 10),
    error('kein festes Schuhwerk', 10),
    error('keine Schutzhandschuhe', 10),
    error('kein Brusttuch', 5) // TODO display the name, e.g. "kein Brusttuch Gruppenführer/Melder"
  ],
  0,
  'gf,me,ma,atf,atm,wtf,wtm,stf,stm'
)
clothes.type = 'clothes'

export const gfMe = {
  roles: ['gf', 'me'],
  errors: [
    clothes,
    error('Im Einsatzbefehl des GF fehlen', [
      error('Wasserentnahmestelle', 2, 0),
      error('Lage des Verteilers', 2, 0),
      multiError('Einheit', 2),
      multiError('Auftrag', 2),
      multiError('Mittel', 2),
      multiError('Ziel', 2),
      multiError('Weg', 2)
    ], [0, 2, 3], 'gf'),
    multiError('Einsatzbefehl für Melder nicht, zu früh oder falsch gegeben', 5, 3, 'gf'),
    error('Fehlende Ausrüstungsgegenstände', [
      error('Handscheinwerfer', 5)
    ], 1),
    error('Melder nicht mit dem Gruppenführer gemeinsam nach vorn gegangen', 2, 1, 'me'),
    error('Fehler am Wassergraben', 5, 1),
    error('Verteiler ohne Befehl übernommen', 5, 3, 'me'),
    error('Einsatzbefehl nicht oder falsch wiederholt', 5, 3, 'me'),
    error('Verteiler nicht übernommen', 5, 3, 'me'),
    error('Bei der Übernahme des Verteilers Handscheinwerfer nicht mitgenommen', 5, 3, 'me'),
    error('C-Druckschlauch vom Schlauchtrupp nicht angekuppelt', 10, 3, 'me'),
    error('Verteiler vor "3. Rohr Wasser marsch" geöffnet', 5, 3, 'me'),
    error('Verteiler nicht richtig geöffnet', 5, 3, 'me'),
    error('Verteiler nicht geöffnet', 10, 3, 'me'),
    error('"Wasser halt!" zu früh gegeben', 5, 4, 'gf'),
    error('"Wasser halt!" nicht gegeben', 10, 4, 'gf'),
    multiError('Nach dem Kommando "... Rohr Wasser halt!" Verteiler nicht ganz geschlossen', 5, 4, 'me'),
    multiError('Nach dem Kommando "... Rohr Wasser halt!" Verteiler nicht geschlossen', 10, 4, 'me'),
    error('Befehl "Angriffstrupp und Wassertrupp Knoten und Stiche anlegen!"', [
      error('zu früh gegeben', 5),
      error('zu spät gegeben', 10),
      error('falsch gegeben', 5),
      error('nicht gegeben', 10)
    ], 4, 'gf'),
    error('Kriechtunnel ausgelassen', 10, 4, 'gf'),
    error('Handscheinwerfer nicht mit zum Knotengestell genommen', 5, 4, 'gf'),
    error('"Übung beendet!" zu früh gegeben', 5, 4, 'gf'),
    error('"Übung beendet!" nicht gegeben', 10, 4, 'gf')
  ]
}

export const gf = gfMe
export const me = gfMe

export const ma = {
  roles: ['ma'],
  errors: [
    clothes,
    multiError('Druckabgänge waren zu Beginn der Übung geöffnet', 5, 0),
    multiError('Blindkupplungen waren zu Beginn der Übung nicht angebracht', 5, 0),
    error('Blindkupplung nur von einem Druckabgang entfernt', 5, 1),
    error('Sammelstück nicht angeschlossen', 10, 1),
    error('Sammelstück nicht mit Kupplungsschlüssel angezogen', 5, 1),
    multiError('B-Druckschlauch falsch angeschlossen', 5, 1),
    multiError('B-Druckschlauch nicht angeschlossen', 10, 1),
    error('Druckabgang vor "Wasser marsch!" des Wassertruppführers geöffnet', 5, 1),
    error('Druckabgang nicht richtig geöffnet', 5, 1),
    error('Druckabgang nicht geöffnet', 10, 1)
  ]
}

export const at = {
  roles: ['atf', 'at', 'atm'],
  errors: [
    clothes,
    error('Einsatzbefehl nicht vollständig wiederholt', [
      error('Einheit fehlte', 2),
      error('Auftrag fehlte', 2),
      error('Mittel fehlte', 2),
      error('Ziel fehlte', 2),
      error('Weg fehlte', 2)
    ], 1, 'atf'),
    error('Fehlende Ausrüstungsgegenstände', [
      error('Handscheinwerfer', 5, null, 'atf'),
      error('CM-Strahlrohr', 5, null, 'atm')
    ], 1, ''),
    error('Fehler am Wassergraben', 5, 1, 'atf,atm'),
    error('Verteiler nicht gesetzt', 10, 1, 'atf'),
    multiError('Die erforderlichen C-Druckschläuche nicht zum Verteiler gebracht', 5, 1, 'atm', 'je Schlauch'),
    error('C-Druckschlauch am falschen Abgang angekuppelt', 5, 1, 'atf'),
    error('C-Druckschlauch nicht am Verteiler angekuppelt', 10, 1, 'atf'),
    error('1. C-Druckschlauch nicht unter der Leiterwand verlegt', 10, 1, 'at'),
    error('Leiterwand ausgelassen', 40, 1, 'atf,atm'),
    multiError('Leiterwand nicht leitermäßig begangen (beidseitig)', 5, 1, 'atf,atm'),
    multiError('Gerät nicht unter der Leiterwand durchgeschoben', 10, 1, 'atf,atm'),
    error('Schlauchverdrehung im 1. C-Druckschlauch', 5, 1, 'at'),
    error('2. C-Druckschlauch nicht ganz als Schlauchreserve verlegt', 5, 1, 'at'),
    error('2. C-Druckschlauch nicht als Schlauchreserve verlegt', 10, 1, 'at'),
    error('Standort nicht links der markierten Stelle an der 40-m-Linie', 5, 1, 'atf,atm'),
    error('"1. Rohr Wasser marsch!" zu früh gegeben', 5, 1, 'atf'),
    error('"1. Rohr Wasser marsch!" nicht gegeben', 10, 1, 'atf'),
    error('Strahlrohr nicht geöffnet', 10, 1, 'atf'),
    error('"1. Rohr Wasser halt!" zu früh gegeben', 5, 4, 'atf'),
    error('"1. Rohr Wasser halt!" nicht gegeben', 10, 4, 'atf'),
    error('Strahlrohr vor "Wasser halt" geschlossen', 5, 4, 'atf'),
    error('Strahlrohr nicht geschlossen', 10, 4, 'atf'),
    error('Strahlrohr vor "Wasser halt" abgelegt', 5, 4, 'atf'),
    error('Standort an der 40-m-Linie zu früh verlassen', 10, 4, 'atf,atm'),
    error('Knoten oder Stich am Knotengestell falsch ausgeführt', 5, 4, 'atf,atm'),
    error('Knoten oder Stich am Knotengestell nicht ausgeführt', 10, 4, 'atf,atm')
  ]
}

export const atf = at
export const atm = at

export const wt = {
  roles: ['wtf', 'wt', 'wtm'],
  errors: [
    clothes,
    error('Standrohr falsch gesetzt', 5, 1, 'wt'),
    error('Standrohr nicht gesetzt', 10, 1, 'wt'),
    error('B-Druckschlauch nicht von der TS zum Standrohr verlegt', 10, 1, 'wt'),
    error('B-Druckschlauch nicht am Standrohr angekuppelt', 10, 1, 'wt'),
    error('B-Druckschlauch nicht von der TS zum Verteiler verlegt', 10, 1, 'wt'),
    multiError('Fehler am Wassergraben', 5, [1, 2], 'wtf,wtm'),
    error('Schlauchverdrehung im B-Schlauch zwischen TS und Verteiler', 5, 1, 'wt'),
    error('B-Druckschlauch nicht gemeinsam an den Verteiler angekuppelt', 5, 1, 'wt'),
    error('B-Druckschlauch nicht an den Verteiler angekuppelt', 10, 1, 'wt'),
    error('"Wasser marsch!" zum Maschinisten zu früh gegeben', 5, 1, 'wtf'),
    error('"Wasser marsch!" zum Maschinisten nicht gegeben', 10, 1, 'wtf'),
    error('"Wassertrupp einsatzbereit!" zum Gruppenführer falsch gegeben', 5, 1, 'wtf'),
    error('"Wassertrupp einsatzbereit!" zum Gruppenführer nicht gegeben', 10, 1, 'wtf'),
    error('Standort vor Wiederholung des Einsatzbefehls verlassen', 5, 2, 'wtf,wtm'),
    error('Einsatzbefehl nicht vollständig wiederholt', [
      error('Einheit fehlte', 2),
      error('Auftrag fehlte', 2),
      error('Mittel fehlte', 2),
      error('Ziel fehlte', 2),
      error('Weg fehlte', 2)
    ], 2, 'wtf'),
    error('Fehlende Ausrüstungsgegenstände', [
      error('Handscheinwerfer', 5, null, 'wtf'),
      error('CM-Strahlrohr', 5, null, 'wtm')
    ], 2, ''),
    error('Fehler an der Hürde', 5, 2, 'wtf,wtm'),
    error('Hürde ausgelassen', 10, 2, 'wtf,wtm'),
    error('Standort nicht rechts der markierten Stelle an der 40-m-Linie', 5, 2, 'wtf,wtm'),
    error('"2. Rohr Wasser marsch!" zu früh gegeben', 5, 2, 'wtf'),
    error('"2. Rohr Wasser marsch!" nicht gegeben ', 10, 2, 'wtf'),
    error('Strahlrohr nicht geöffnet ', 10, 2, 'wtf'),
    error('"2. Rohr Wasser halt!" zu früh gegeben', 5, 4, 'wtf'),
    error('"2. Rohr Wasser halt!" nicht gegeben ', 10, 4, 'wtf'),
    error('Strahlrohr vor "Wasser halt!" geschlossen', 5, 4, 'wtf'),
    error('Strahlrohr nicht geschlossen ', 10, 4, 'wtf'),
    error('Strahlrohr vor "Wasser halt!" abgelegt', 5, 4, 'wtf'),
    error('Standort an der 40-m-Linie zu früh verlassen', 10, 4, 'wtf,wtm'),
    error('Knoten oder Stich am Knotengestell falsch ausgeführt', 5, 4, 'wtf,wtm'),
    error('Knoten oder Stich am Knotengestell nicht ausgeführt', 10, 4, 'wtf,wtm')
  ]
}

export const wtf = wt
export const wtm = wt

export const st = {
  roles: ['stf', 'st', 'stm'],
  errors: [
    clothes,
    multiError('Fehler am Wassergraben', 5, [1, 3], 'stf,stm'),
    multiError('Die erforderlichen C-Druckschläuche nicht zum Verteiler gebracht', 5, 1, 'st', 'je Schlauch'),
    multiError('Niederschraubventil des Verteilers nicht richtig geöffnet', 5, 1, 'stf'),
    multiError('Niederschraubventil des Verteilers nicht geöffnet', 10, 1, 'stf'),
    error('Standort vor Einsatzbefehl für den WT verlassen', 5, 2, 'stf,stm'),
    multiError('Fehler an der Hürde', 5, 2, 'stf,stm'),
    multiError('Hürde ausgelassen', 10, 2, 'stf,stm'),
    error('2. C-Druckschlauch (WT) nicht ausgerollt', 10, 2, 'st'),
    error('Standort vor "2. Rohr Wasser marsch" verlassen', 5, 2, 'stf,stm'),
    error('2. C-Druckschlauch (WT) nicht ganz als Schlauchreserve verlegt', 5, 2, 'st'),
    error('1. C-Druckschlauch (WT) vor "2. Rohr Wasser marsch" ausgerollt', 5, 2, 'st'),
    error('1. C-Druckschlauch (WT) nicht unter der Hürde verlegt', 10, 2, 'st'),
    error('Schlauchverdrehung im 1. C-Druckschlauch (WT)', 5, 2, 'st'),
    error('1. C-Druckschlauch (WT) nicht verlegt', 10, 2, 'st'),
    error('C-Druckschlauch am falschen Abgang angekuppelt', 5, 2, 'stf'),
    error('C-Druckschlauch nicht am Verteiler angekuppelt', 10, 2, 'stf'),
    error('Standort vor Wiederholung des eigenen Einsatzbefehls verlassen', 5, 3, 'stf,stm'),
    error('Einsatzbefehl nicht vollständig wiederholt', [
      error('Einheit fehlte', 2),
      error('Auftrag fehlte', 2),
      error('Mittel fehlte', 2),
      error('Ziel fehlte', 2),
      error('Weg fehlte', 2)
    ], 3, 'stf'),
    error('Fehlende Ausrüstungsgegenstände', [
      error('Handscheinwerfer', 5, null, 'stf'),
      error('CM-Strahlrohr', 5, null, 'stm')
    ], 3, ''),
    error('Kriechtunnel ausgelassen', 10, 3, 'stf,stm'),
    error('1. C-Druckschlauch nicht ausgerollt und durch den Kriechtunnel verlegt', 10, 3, 'st'),
    error('Schlauchverdrehung im 1. C-Druckschlauch (ST)', 5, 3, 'st'),
    error('2. C-Druckschlauch (ST) nicht ganz als Schlauchreserve verlegt', 5, 3, 'st'),
    error('2. C-Druckschlauch (ST) nicht als Schlauchreserve verlegt', 10, 3, 'st'),
    error('Standort nicht links und rechts der markierten Stelle an der 40-m-Linie', 5, 3, 'stf,stm'),
    error('"3. Rohr Wasser marsch!" zu früh gegeben', 5, 3, 'stf'),
    error('"3. Rohr Wasser marsch!" nicht gegeben', 10, 3, 'stf'),
    error('Strahlrohr nicht geöffnet', 10, 3, 'stf'),
    error('"3. Rohr Wasser halt!" zu früh gegeben', 5, 4, 'stf'),
    error('"3. Rohr Wasser halt!" nicht gegeben', 10, 4, 'stf'),
    error('Strahlrohr vor "Wasser halt!" geschlossen', 5, 4, 'stf'),
    error('Strahlrohr nicht geschlossen', 10, 4, 'stf'),
    error('Strahlrohr vor "Wasser halt!" abgelegt', 5, 4, 'stf')
  ]
}

export const stf = st
export const stm = st

export function getErrorsOfPhase (phase, name) {
  return {
    name,
    errors: {
      gfMe: {
        ...gfMe,
        errors: filterPhaseErrors(gfMe.errors, phase)
      },
      ma: {
        ...ma,
        errors: filterPhaseErrors(ma.errors, phase)
      },
      at: {
        ...at,
        errors: filterPhaseErrors(at.errors, phase)
      },
      wt: {
        ...wt,
        errors: filterPhaseErrors(wt.errors, phase)
      },
      st: {
        ...st,
        errors: filterPhaseErrors(st.errors, phase)
      }
    }
  }
}

export const phases = [
  getErrorsOfPhase(0, 'Übungsbeginn'),
  getErrorsOfPhase(1, 'Vornahme des 1. Rohres'),
  getErrorsOfPhase(2, 'Vornahme des 2. Rohres'),
  getErrorsOfPhase(3, 'Vornahme des 3. Rohres'),
  getErrorsOfPhase(4, 'Tätigkeiten nach der Brandbekämpfung')
]
