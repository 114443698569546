import React from 'react'
import { findDOMNode } from 'react-dom'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import { withStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import PlusIcon from 'mdi-material-ui/Plus'
import MinusIcon from 'mdi-material-ui/Minus'
import cx from 'classnames'

const styles = (theme) => ({
  root: {
    minWidth: 32,
    minHeight: 32,
    borderRadius: '50%',
    color: '#000 !important',
    lineHeight: 'initial' // fix number not being vertically centered
  },
  active: {
    border: `2px solid ${theme.palette.primary.main}`,
    padding: '5px 6px',
    '& $badge': {
      bottom: -3,
      right: -4
    }
  },
  open: {
    background: `${fade(theme.palette.text.primary, 0.12)} !important`
  },
  badge: {
    borderRadius: '50%',
    background: theme.palette.primary.main,
    color: '#fff',
    width: 16,
    lineHeight: '16px',
    height: 16,
    position: 'absolute',
    bottom: -1,
    right: -2
  },
  popoverPaper: {
    borderRadius: 24,
    paddingRight: 8,
    marginLeft: 8
  }
})

class PointsButton extends React.PureComponent {
  state = {
    open: false
  }

  componentWillUnmount () {
    if (this._longPress) {
      clearTimeout(this._longPress)
      this._longPress = null
    }
  }

  showPopover = () => this.setState({ open: true })

  hidePopover = () => this.setState({ open: false })

  handleButtonRef = (ref) => this.buttonNode = ref != null ? findDOMNode(ref) : null

  handlePlus = () => {
    this.props.onChange(this.props.role, this.props.errorId, this.props.checked + 1)
  }

  handleMinus = () => {
    this.props.onChange(this.props.role, this.props.errorId, this.props.checked - 1)
  }

  handleToggle = () => {
    this.props.onChange(this.props.role, this.props.errorId, this.props.checked === 1 ? 0 : 1)
  }

  handleTouchStart = (e) => {
    if (this._longPress) {
      clearTimeout(this._longPress)
      this._longPress = null
    }
    this._longPress = setTimeout(this.showPopover, 500)
  }

  handleTouchEnd = (e) => {
    if (this._longPress) {
      clearTimeout(this._longPress)
      this._longPress = null
    }
  }

  handleContextMenu = (e) => e.preventDefault() // don't show context menu on long press on Android

  render () {
    const { classes, points, checked, multiple, disabled } = this.props
    const { open } = this.state

    if (multiple) {
      return (
        <React.Fragment>
          <Button
            ref={this.handleButtonRef}
            size='small'
            className={cx(classes.root, {[classes.active]: checked > 0, [classes.open]: open})}
            disabled={disabled}
            onClick={this.handlePlus}
            onTouchStart={this.handleTouchStart}
            onTouchEnd={this.handleTouchEnd}
            onContextMenu={this.handleContextMenu}
          >
            {points}
            {checked > 0 && (<div className={classes.badge}>{checked}</div>)}
          </Button>
          <Popover
            anchorEl={this.buttonNode}
            anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
            transformOrigin={{ horizontal: 'right', vertical: 'center' }}
            open={open}
            onClose={this.hidePopover}
            classes={{ paper: classes.popoverPaper }}
          >
            <IconButton
              className={classes.popoverButton}
              onClick={this.handleMinus}
              disabled={checked < 1}
              size='small'
            >
              <MinusIcon />
            </IconButton>
            <IconButton
              className={classes.popoverButton}
              onClick={this.handlePlus}
              size='small'
            >
              <PlusIcon />
            </IconButton>
            <Button
              size='small'
              className={cx(classes.root, {[classes.active]: checked > 0})}
              disabled
            >
              {points}
              {checked > 0 && (<div className={classes.badge}>{checked}</div>)}
            </Button>
          </Popover>
        </React.Fragment>
      )
    } else {
      return (
        <Button
          size='small'
          className={cx(classes.root, {[classes.active]: checked > 0})}
          disabled={disabled}
          onClick={this.handleToggle}
          onContextMenu={this.handleContextMenu}
        >
          {points}
        </Button>
      )
    }
  }
}

export default React.memo(withStyles(styles)(PointsButton))
