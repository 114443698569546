import React from 'react'
import { connect } from 'react-redux'
import * as actions from '../actions'
import RatingAccordion from '../components/RatingAccordion'
import PhaseRatingView from '../components/PhaseRatingView'

export default connect(
  (state) => ({
    mistakes: state.partA.mistakes,
    group: state.groupManagement.members.filter((m) => m.isInGroup),
    waterSource: state.settings.waterSource,
    timeSorting: state.settings.timeSorting,
    hideClothErrors: state.settings.hideClothErrors,
    hideFastCouplingErrors: state.settings.hideFastCouplingErrors
  }),
  {
    onToggleMistake: actions.toggleMistake
  }
)(({ timeSorting, ...props }) => {
  if (timeSorting) {
    return <PhaseRatingView {...props} />
  } else {
    return <RatingAccordion {...props} />
  }
})
