import React, { useEffect, useRef } from "react";
import FullscreenDialog from "./FullscreenDialog";

const generateDialogId = () => Math.random().toString(36).substring(7);

const dialogsStack = [];
window.dialogsStack = dialogsStack;

export function getOpenDialogsCount() {
  return dialogsStack.length;
}

export function useBackButtonOpen(defaultOpen = false) {
  const id = useRef(generateDialogId());
  const [open, setOpen] = React.useState(defaultOpen);

  useEffect(() => {
    const myId = id.current;
    if (open) {
      window.history.pushState(null, null, window.location.href);
      dialogsStack.push(myId);
    } else {
      const index = dialogsStack.indexOf(myId);
      if (index >= 0) {
        dialogsStack.splice(index, 1);
      }
    }
    return () => {
      const index = dialogsStack.indexOf(myId);
      if (index >= 0) {
        dialogsStack.splice(index, 1);
      }
    };
  }, [open]);

  useEffect(() => {
    const handler = () => {
      if (dialogsStack[dialogsStack.length - 1] === id.current) {
        setOpen(false);
      }
    };
    window.addEventListener("popstate", handler);
    return () => {
      window.removeEventListener("popstate", handler);
    };
  }, []);

  return [open, setOpen];
}

export default function BackButtonFullscreenDialog({
  open,
  onClose,
  ...other
}) {
  const id = useRef(generateDialogId());

  useEffect(() => {
    const myId = id.current;
    if (open) {
      window.history.pushState(null, null, window.location.href);
      dialogsStack.push(myId);
    } else {
      const index = dialogsStack.indexOf(myId);
      if (index >= 0) {
        dialogsStack.splice(index, 1);
      }
    }
    return () => {
      const index = dialogsStack.indexOf(myId);
      if (index >= 0) {
        dialogsStack.splice(index, 1);
      }
    };
  }, [open]);

  useEffect(() => {
    const handlePopState = (e) => {
      if (dialogsStack[dialogsStack.length - 1] === id.current) {
        onClose();
      }
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [onClose]);

  return <FullscreenDialog {...other} open={open} onClose={onClose} />;
}
