import { useSnackbar } from "material-ui-snackbar-provider";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import useTimer from "../../../hooks/useTimer";
import * as actions from "../actions";
import TimerComponent from "../components/Timer";

export default function Timer(props) {
  const activeRunner = useSelector(
    (state) => state.relayRace.runnerTimes.length
  );
  const firstRunnerStarted = useSelector((state) =>
    Date.parse(state.relayRace.timer.startedAt)
  );
  const activeRunnerStarted = useSelector((state) =>
    state.relayRace.runnerTimes.length > 0
      ? state.relayRace.runnerTimes[state.relayRace.runnerTimes.length - 1]
          .start
      : null
  );
  const groupSize = useSelector((state) => state.settings.lspGroupSize);

  const { startTimer, stopTimer, resetTimer, unresetTimer, time, active } =
    useTimer((state) => state.relayRace.timer, actions);
  const snackbar = useSnackbar();
  const dispatch = useDispatch();

  const handleToggleTimer = useCallback(() => {
    if (active) {
      if (activeRunner === groupSize) {
        stopTimer();
        snackbar.showMessage("Stoppuhr angehalten");
      } else {
        dispatch(actions.switchRunner(Date.now()));
      }
    } else if (time > 0) {
      resetTimer();
      snackbar.showMessage("Stoppuhr zurückgesetzt", "Rückgängig", () => {
        unresetTimer();
      });
    } else {
      startTimer();
      snackbar.showMessage("Stoppuhr gestartet");
    }
  }, [
    active,
    activeRunner,
    dispatch,
    groupSize,
    resetTimer,
    snackbar,
    startTimer,
    stopTimer,
    time,
    unresetTimer,
  ]);

  return (
    <TimerComponent
      {...props}
      seconds={Math.round(time / 1000)}
      maximumSeconds={groupSize === 9 ? 4 * 60 + 15 : 2 * 60 + 50}
      onClick={handleToggleTimer}
      activeRunner={active ? activeRunner : null}
      activeRunnerSeconds={
        active
          ? Math.round((firstRunnerStarted - activeRunnerStarted + time) / 1000)
          : 0
      }
    />
  );
}
