import React from 'react'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import Paper from '@material-ui/core/Paper'
import TimerIcon from 'mdi-material-ui/Timer'
import RatingIcon from 'mdi-material-ui/ThumbsUpDown'

export default ({ activePage, onChangePage, classes }) => (
  <Paper className={classes.root}>
    <BottomNavigation
      value={activePage}
      onChange={onChangePage}
      showLabels
      className={classes.bottomNavigation}
    >
      <BottomNavigationAction label='Stoppuhr' value='timer' icon={<TimerIcon />} />
      <BottomNavigationAction label='Auswertung' value='results' icon={<RatingIcon />} />
    </BottomNavigation>
  </Paper>
)
