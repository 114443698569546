import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { SnackbarProvider, withSnackbar } from "material-ui-snackbar-provider";
import DotsVertical from "mdi-material-ui/DotsVertical";
import { AppBarLayout, AppBar } from "../AppFrame";
import BottomNavigation from "./components/BottomNavigation";
import Shots from "./containers/Shots";
import Results from "./containers/Results";
import MenuIconButton from "../../components/MenuIconButton";
import { RESET_RESULTS } from "./actions";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  navigation: {
    width: "100%",
    zIndex: 101
  },
  menuButton: {
    marginRight: -12
  },
  snackbar: {
    marginBottom: 56,
    zIndex: 100
  }
};

class ShotPut extends PureComponent {
  state = {
    activePage: "shots"
  };

  handleChangePage = (event, value) => {
    this.setState({ activePage: value });
  };

  handleResetResults = () => {
    this.props.dispatch({ type: RESET_RESULTS });
  };

  render() {
    const {
      classes,
      className,
      onOpenDrawer,
      dispatch,
      mistakes,
      ...other
    } = this.props;

    const { activePage } = this.state;

    return (
      <SnackbarProvider
        ButtonProps={{ color: "primary" }}
        SnackbarProps={{
          autoHideDuration: 4000,
          classes: { root: classes.snackbar }
        }}
      >
        <AppBarLayout
          appBarRenderer={props => (
            <AppBar {...props} title="Kugelstoßen" onButtonClick={onOpenDrawer}>
              <MenuIconButton
                icon={DotsVertical}
                color="inherit"
                className={classes.menuButton}
              >
                <MenuItem onClick={this.handleResetResults}>
                  Stöße zurücksetzen
                </MenuItem>
              </MenuIconButton>
            </AppBar>
          )}
        >
          <div className={classes.root} {...other}>
            {activePage === "shots" && (
              <Shots
                style={{
                  flex: 1,
                  overflow: "auto"
                }}
              />
            )}
            {activePage === "results" && (
              <Results
                style={{
                  flex: 1,
                  overflow: "auto"
                }}
              />
            )}

            <BottomNavigation
              classes={{ root: classes.navigation }}
              activePage={activePage}
              onChangePage={this.handleChangePage}
            />
          </div>
        </AppBarLayout>
      </SnackbarProvider>
    );
  }
}

export default connect(() => ({}))(withStyles(styles)(withSnackbar()(ShotPut)));
