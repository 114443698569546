import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import red from '@material-ui/core/colors/red'
import cx from 'classnames'
import SwipeableViews from 'react-swipeable-views'
import { formatLength } from './util'

const styles = (theme) => ({
  root: {},
  top: {
    height: 'calc(50% - 1px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  total: {
    textAlign: 'center',
    fontWeight: 300,
  },
  tooShort: {
    color: red[500]
  },
  smallTime: {
    textAlign: 'center'
  },
  tabsScroller: {
    '&::-webkit-scrollbar': { 
      display: 'none'
    }
  },
  shot: {
    padding: '16px 24px 0'
  }
})

const swipeableViewStyles = {
  root: {
    height: '50%',
    transform: 'translateZ(0)'
  },
  container: {
    height: '100%'
  }
}

function Shots (props) {
  const {
    classes,
    shots,
    groupSize,
    onShotChanged,
    ...other
  } = props

  const [index, setIndex] = React.useState(0)

  const minimumDistance = groupSize === 6 ? 36 : 55
  const totalDistance = shots.reduce((sum, { distance, invalid }) => invalid ? sum : sum + (parseFloat(distance) || 0), 0)

  React.useEffect(() => {
    if (index > 0 && shots.every(s => s.distance == null)) {
      setIndex(0)
    }
  }, [index, shots])

  return (
    <div {...other} className={classes.root}>
      <div className={classes.top}>
        <Typography
          variant='h2'
          className={cx(classes.total, { [classes.tooShort]: totalDistance < minimumDistance })}
        >
          {formatLength(totalDistance)} m
        </Typography>
        <Typography
          className={classes.smallTime}
        >
          Mindestweite: {minimumDistance} m
        </Typography>
      </div>
      <SwipeableViews
        containerStyle={swipeableViewStyles.container}
        style={swipeableViewStyles.root}
        index={index}
        onChangeIndex={(i) => setIndex(Math.min(groupSize - 1, Math.max(0, i)))}
      >
        {shots.map(({ distance, invalid }, i) => (
          <div key={i}>
            <div className={classes.shot}>
              <TextField
                type='number'
                label={`${i + 1}. Stoß`}
                placeholder='Weite'
                id={`shot-input-${i}`}
                value={distance || ''}
                onChange={(e) => onShotChanged(i, { distance: e.target.value, invalid })}
                fullWidth
                autoComplete='off'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">m</InputAdornment>
                  )
                }}
                onKeyUp={(e) => {
                  if (i < groupSize - 1 && e.keyCode === 13) {
                    setIndex(i + 1)
                    document.getElementById(`shot-input-${i + 1}`).select()
                  }
                }}
                onFocus={(e) => {
                  e.preventDefault()
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={invalid}
                    onChange={(e) => onShotChanged(i, { distance, invalid: e.target.checked })}
                    value='invalid'
                    color='primary'
                  />
                }
                label='Ungültig (z.B. übergetreten)'
              />
            </div>
          </div>
        ))}
      </SwipeableViews>
    </div>
  )
}

export default withStyles(styles)(Shots)
