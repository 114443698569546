import React from "react";
import ReactDOM from "react-dom";
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import { SplashScreen } from "@capacitor/splash-screen";
import "./index.css";
import registerServiceWorker from "./registerServiceWorker";
import App, { store } from "./App";
import {
  installServiceWorker,
  updateServiceWorker,
} from "./modules/AppFrame/actions";

ReactDOM.render(<App />, document.getElementById("root"), () => {
  if (Capacitor.isNativePlatform()) {
    StatusBar.setBackgroundColor({
      color: "#F45F00",
    });
    StatusBar.setStyle({
      style: Style.Dark,
    });
    SplashScreen.hide();
  }
});

if (!Capacitor.isNativePlatform()) {
  registerServiceWorker({
    onInstall: () => store.dispatch(installServiceWorker()),
    onUpdate: () => store.dispatch(updateServiceWorker()),
  });
}
