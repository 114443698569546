import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import Numeric1CircleOutline from 'mdi-material-ui/Numeric1CircleOutline'
import Numeric2CircleOutline from 'mdi-material-ui/Numeric2CircleOutline'
import Numeric3CircleOutline from 'mdi-material-ui/Numeric3CircleOutline'
import Numeric4CircleOutline from 'mdi-material-ui/Numeric4CircleOutline'
import Numeric5CircleOutline from 'mdi-material-ui/Numeric5CircleOutline'
import Numeric6CircleOutline from 'mdi-material-ui/Numeric6CircleOutline'
import Numeric7CircleOutline from 'mdi-material-ui/Numeric7CircleOutline'
import Numeric8CircleOutline from 'mdi-material-ui/Numeric8CircleOutline'
import Numeric9CircleOutline from 'mdi-material-ui/Numeric9CircleOutline'
import EditLengthDialog from './EditLengthDialog'
import { positionsToLengths } from '../util'

const styles = (theme) => ({
  root: {
    padding: 8
  },
  line: {
    height: 28,
    width: 2,
    background: theme.palette.divider,
    position: 'absolute',
    top: -14,
    left: 27
  }
})

function NumberIcon ({ number }) {
  switch (number) {
    case 1:
      return <Numeric1CircleOutline />
    case 2:
      return <Numeric2CircleOutline />
    case 3:
      return <Numeric3CircleOutline />
    case 4:
      return <Numeric4CircleOutline />
    case 5:
      return <Numeric5CircleOutline />
    case 6:
      return <Numeric6CircleOutline />
    case 7:
      return <Numeric7CircleOutline />
    case 8:
      return <Numeric8CircleOutline />
    case 9:
      return <Numeric9CircleOutline />
    default:
      return null
  }
}

function Positions (props) {
  const {
    classes,
    positions,
    onRunnerUpdated,
    groupSize,
    ...other
  } = props

  const [editLength, setEditLength] = React.useState(null)

  return (
    <div {...other} className={classes.root}>
      <List>
        {positionsToLengths(positions, groupSize).map(({ start, length, name }, i) => (
          <ListItem
            key={i}
            role={undefined}
            dense
            button
            onClick={() => setEditLength({ runner: i, length, name, open: true })}
          >
            {i > 0 && (<div className={classes.line} />)}
            <ListItemIcon>
              <NumberIcon number={i + 1} />
            </ListItemIcon>
            <ListItemText
              key={i}
              primary={`${name || `Läufer ${i + 1}`}: ${length}\u2009m`}
              secondary={`Start bei ${start}\u2009m`}
            />
          </ListItem>
        ))}
      </List>
      <EditLengthDialog
        open={editLength ? editLength.open : false}
        onClose={() => setEditLength({ ...editLength, open: false })}
        runner={editLength && editLength.runner}
        defaultLength={editLength && editLength.length}
        defaultName={editLength && editLength.name}
        onSubmit={({ length, name }) => {
          setEditLength({ ...editLength, open: false })
          onRunnerUpdated(editLength.runner, length, name)
        }}
      />
    </div>
  )
}

export default withStyles(styles)(Positions)
