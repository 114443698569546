export const INSTALL_SW = 'INSTALL_SW'
export const UPDATE_SW = 'UPDATE_SW'
export const SET_PAGE = 'SET_PAGE'

export function installServiceWorker () {
  return { type: INSTALL_SW }
}

export function updateServiceWorker () {
  return { type: UPDATE_SW }
}

export function setPage (page) {
  return { type: SET_PAGE, payload: page }
}
