import { ADD_MEMBER, REMOVE_MEMBER, TOGGLE_IS_IN_GROUP } from './actions'

export function members (state = [], action) {
  switch (action.type) {
    case ADD_MEMBER:
      return [
        ...state,
        {
          ...action.payload,
          isInGroup: false
        }
      ]
    case REMOVE_MEMBER:
      return state.filter(({ id }) => id !== action.payload)
    case TOGGLE_IS_IN_GROUP:
      return state.map((member) => member.id === action.payload ? {
        ...member,
        isInGroup: !member.isInGroup
      } : member)
    default:
      return state
  }
}
