import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import withStyles from '@material-ui/core/styles/withStyles'

function getAllowedYears () {
  const currentYear = new Date().getFullYear()
  const allowedYears = []
  for (let age = 10; age <= 18; age++) {
    allowedYears.push(currentYear - age)
  }
  return allowedYears
}

const styles = {
  content: {
    display: 'flex'
  },
  name: {
    flex: 1,
    marginRight: 8
  },
  year: {
    minWidth: 80
  }
}

const defaultState = {
  name: '',
  yearOfBirth: getAllowedYears()[0]
}

class AddMemberDialog extends Component {
  state = defaultState

  handleChangeName = (e) => {
    this.setState({ name: e.target.value })
  }

  handleChangeYearOfBirth = (e) => {
    this.setState({ yearOfBirth: e.target.value })
  }

  handleSubmit = () => {
    this.props.onSubmit({
      name: this.state.name,
      yearOfBirth: parseInt(this.state.yearOfBirth, 10)
    })
    this.setState(defaultState)
  }

  handleCancel = () => {
    this.setState(defaultState)
    this.props.onClose()
  }

  render () {
    const {
      classes,
      onSubmit, // eslint-disable-line
      ...other
    } = this.props

    const {
      name,
      yearOfBirth
    } = this.state

    return (
      <Dialog {...other}>
        <DialogTitle>Teilnehmer hinzufügen</DialogTitle>
        <DialogContent className={classes.content}>
          <TextField
            label='Name'
            id='name'
            value={name}
            onChange={this.handleChangeName}
            className={classes.name}
            autoComplete='off'
            autoFocus
          />
          <FormControl>
            <InputLabel htmlFor='yearOfBirth'>Jahrgang</InputLabel>
            <Select
              native
              className={classes.year}
              value={yearOfBirth}
              onChange={this.handleChangeYearOfBirth}
              inputProps={{
                id: 'yearOfBirth'
              }}
            >
              {getAllowedYears().map((year) => (<option key={year} value={year}>{year}</option>))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleCancel}
          >
            Abbrechen
          </Button>
          <Button
            onClick={this.handleSubmit}
            color='primary'
            disabled={name.trim().length === 0}
          >
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(AddMemberDialog)
