export const START_TIMER = "RelayRace/START_TIMER";
export const STOP_TIMER = "RelayRace/STOP_TIMER";
export const RESET_TIMER = "RelayRace/RESET_TIMER";
export const UNRESET_TIMER = "RelayRace/UNRESET_TIMER";
export const RESUME_TIMER = "RelayRace/RESUME_TIMER";
export const RESET_RESULTS = "RelayRace/RESET_RESULTS";
export const SWITCH_RUNNER = "RelayRace/SWITCH_RUNNER";
export const SET_POSITION = "RelayRace/SET_POSITION";

export function startTimer(time = new Date()) {
  return { type: START_TIMER, payload: time.toISOString() };
}

export function stopTimer(time = new Date()) {
  return { type: STOP_TIMER, payload: time.toISOString() };
}

export function resumeTimer() {
  return { type: RESUME_TIMER };
}

export function resetTimer() {
  return { type: RESET_TIMER };
}

export function unresetTimer() {
  return { type: UNRESET_TIMER };
}

export function resetResults() {
  return { type: RESET_RESULTS };
}

export function switchRunner(time) {
  return { type: SWITCH_RUNNER, payload: time };
}

export function setRunner(runner, length, name) {
  return { type: SET_POSITION, payload: { runner, length, name } };
}
