import { connect } from 'react-redux'
import MembersList from '../components/MembersList'
import { addMember, removeMember, toggleIsInGroup } from '../actions'

export default connect(
  (state) => ({
    members: state.groupManagement.members
  }),
  {
    onToggleIsInGroup: toggleIsInGroup,
    onAddMember: addMember,
    onDeleteMember: removeMember
  }
)(MembersList)
