import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography'
import red from '@material-ui/core/colors/red'
import cx from 'classnames'
import { formatTime } from '../../util/time'

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    userSelect: 'none',

    '&:active $time': {
      opacity: 0.8
    }
  },
  time: {
    textAlign: 'center',
    fontWeight: 300
  },
  tooSlow: {
    color: red[500]
  },
  smallTime: {
    textAlign: 'center'
  }
})

export default withStyles(styles)(({ classes, seconds = 0, maximumSeconds, knotsSeconds, ...other }) => (
  <ButtonBase {...other} className={classes.root}>
    <Typography
      variant='h1'
      className={cx(classes.time, { [classes.tooSlow]: maximumSeconds < seconds })}
    >
      {formatTime(seconds)}
    </Typography>
    <Typography
      className={classes.smallTime}
    >
      Zeitvorgabe: {formatTime(maximumSeconds)}
    </Typography>
    <Typography
      className={classes.smallTime}
    >
      Knoten und Stiche: {knotsSeconds != null ? formatTime(knotsSeconds) : '0:00'}
    </Typography>
  </ButtonBase>
))
