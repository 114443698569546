import React from 'react'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import { withSnackbar } from 'material-ui-snackbar-provider'
import NavigationDrawer from './NavigationDrawer'
import theme from './theme'
import Settings from '../../Settings'
import BackButtonFullscreenDialog from '../../../components/BackButtonFullscreenDialog'

class AppFrame extends React.PureComponent {
  componentDidUpdate ({ serviceWorkerInstalled, serviceWorkerUpdated }) {
    const { snackbar } = this.props

    if (!serviceWorkerInstalled && this.props.serviceWorkerInstalled) {
      snackbar.showMessage('Alle Daten wurden heruntergeladen. Pocket JF kann jetzt auch ohne Internetverbindung genutzt werden.')
    }
    if (!serviceWorkerUpdated && this.props.serviceWorkerUpdated) {
      // TODO Service worker was updated, show a message
    }
  }

  render () {
    const {
      children,
      showDrawer,
      onOpenDrawer,
      onCloseDrawer,
      selectedPage,
      onChangeRoute,
      showSettings,
      onCloseSettings
    } = this.props

    return (
      <MuiThemeProvider theme={theme}>
        <NavigationDrawer
          open={showDrawer}
          onOpen={onOpenDrawer}
          onClose={onCloseDrawer}
          selectedPage={selectedPage}
          onChangeRoute={onChangeRoute}
        />
        {React.cloneElement(React.Children.only(children), {
          onOpenDrawer
        })}
        <BackButtonFullscreenDialog
          open={showSettings}
          onClose={onCloseSettings}
        >
          <Settings
            onClose={onCloseSettings}
          />
        </BackButtonFullscreenDialog>
      </MuiThemeProvider>
    )
  }
}

export default withSnackbar()(AppFrame)
