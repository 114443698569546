export const START_TIMER = "SpeedExercise/START_TIMER";
export const STOP_TIMER = "SpeedExercise/STOP_TIMER";
export const RESET_TIMER = "SpeedExercise/RESET_TIMER";
export const UNRESET_TIMER = "SpeedExercise/UNRESET_TIMER";
export const RESUME_TIMER = "SpeedExercise/RESUME_TIMER";
export const RESET_RESULTS = "SpeedExercise/RESET_RESULTS";

export function startTimer(time = new Date()) {
  return { type: START_TIMER, payload: time.toISOString() };
}

export function stopTimer(time = new Date()) {
  return { type: STOP_TIMER, payload: time.toISOString() };
}

export function resumeTimer() {
  return { type: RESUME_TIMER };
}

export function resetTimer() {
  return { type: RESET_TIMER };
}

export function unresetTimer() {
  return { type: UNRESET_TIMER };
}

export function resetResults() {
  return { type: RESET_RESULTS };
}
