import * as actions from './actions'

const emptyShot = {
  distance: null,
  invalid: false
}

const defaultState = [
  emptyShot,
  emptyShot,
  emptyShot,
  emptyShot,
  emptyShot,
  emptyShot,
  emptyShot,
  emptyShot,
  emptyShot
]

export function shots (state = defaultState, action) {
  switch (action.type) {
    case actions.SET_SHOT:
      return state.map((shot, i) => i === action.payload.index
        ? action.payload.shot
        : shot)
    case actions.RESET_RESULTS:
      return defaultState
    default:
      return state
  }
}
