import { App } from "@capacitor/app";
import { SnackbarProvider } from "material-ui-snackbar-provider";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { getOpenDialogsCount } from "../../../components/BackButtonFullscreenDialog";
import GroupManagement from "../../GroupManagement";
import PartA from "../../PartA";
import PartB from "../../PartB";
import RelayRace from "../../RelayRace";
import ShotPut from "../../ShotPut";
import SpeedExercise from "../../SpeedExercise";
import * as actions from "../actions";
import AppFrame from "../components/AppFrame";

function AppFrameContainer({ selectedPage, onChangePage, ...other }) {
  const [showDrawer, setShowDrawer] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const openSettingsTimeout = useRef();

  useEffect(() => {
    return () => {
      if (openSettingsTimeout.current != null) {
        clearTimeout(openSettingsTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    const handle = App.addListener("backButton", () => {
      if (getOpenDialogsCount() === 0) {
        App.exitApp();
      } else {
        window.history.back();
      }
    });
    return () => {
      handle.remove();
    };
  }, []);

  const handleOpenDrawer = useCallback(() => setShowDrawer(true), []);

  const handleCloseDrawer = useCallback(() => setShowDrawer(false), []);

  const handleChangeRoute = useCallback(
    (route) => {
      if (route === "Settings") {
        setShowDrawer(false);
        openSettingsTimeout.current = setTimeout(() => {
          openSettingsTimeout.current = null;
          setShowSettings(true);
        }, 225);
      } else {
        onChangePage(route);
        setShowDrawer(false);
      }
    },
    [onChangePage]
  );

  const handleCloseSettings = useCallback(() => setShowSettings(false), []);

  const ActivePage = {
    PartA,
    PartB,
    Group: GroupManagement,
    SpeedExercise,
    ShotPut,
    RelayRace,
  }[selectedPage];

  return (
    <SnackbarProvider snackbarProps={{ autoHideDuration: 10000 }}>
      <AppFrame
        showDrawer={showDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        selectedPage={selectedPage}
        onChangeRoute={handleChangeRoute}
        showSettings={showSettings}
        onCloseSettings={handleCloseSettings}
        {...other}
      >
        <ActivePage />
      </AppFrame>
    </SnackbarProvider>
  );
}

export default connect(
  (state) => ({
    serviceWorkerInstalled: state.app.serviceWorker.installed,
    serviceWorkerUpdated: state.app.serviceWorker.updated,
    selectedPage: state.app.selectedPage,
  }),
  {
    onChangePage: actions.setPage,
  }
)(AppFrameContainer);
