export const SET_SHOT = 'ShotPut/ADD_SHOT'
export const RESET_RESULTS = 'ShotPut/RESET_RESULTS'

export function setShot (index, shot) {
  return { type: SET_SHOT, payload: { index, shot } }
}

export function resetResults () {
  return { type: RESET_RESULTS }
}
