import * as actions from "./actions";

export function timer(
  state = {
    active: false,
    startedAt: null,
    stoppedAt: null,
    time: null,
  },
  action
) {
  switch (action.type) {
    case actions.START_TIMER:
      return {
        ...state,
        active: true,
        startedAt: action.payload,
        stoppedAt: null,
        time: null,
      };
    case actions.STOP_TIMER:
      return {
        ...state,
        active: false,
        stoppedAt: action.payload,
        time: Date.parse(action.payload) - Date.parse(state.startedAt),
      };
    case actions.RESUME_TIMER:
      return {
        ...state,
        active: true,
        stoppedAt: null,
      };
    case actions.RESET_TIMER:
      return {
        ...state,
        previousStartedAt: state.startedAt,
        previousStoppedAt: state.stoppedAt,
        startedAt: null,
        stoppedAt: null,
      };
    case actions.UNRESET_TIMER:
      return {
        ...state,
        startedAt: state.previousStartedAt,
        stoppedAt: state.previousStoppedAt,
      };
    case actions.RESET_RESULTS:
      return {
        ...state,
        active: false,
        startedAt: null,
        stoppedAt: null,
        time: null,
      };
    default:
      return state;
  }
}

export function runnerTimes(state = [], action) {
  switch (action.type) {
    case actions.RESET_RESULTS:
      return [];
    case actions.SWITCH_RUNNER: {
      const previous = state[state.length - 1];
      return [
        ...state.slice(0, -1),
        {
          start: previous.start,
          end: action.payload,
        },
        {
          start: action.payload,
        },
      ];
    }
    case actions.STOP_TIMER: {
      const current = state[state.length - 1];
      return [
        ...state.slice(0, -1),
        {
          start: current.start,
          end: Date.parse(action.payload),
        },
      ];
    }
    case actions.START_TIMER:
      return [
        {
          start: Date.parse(action.payload),
        },
      ];
    default:
      return state;
  }
}

const defaultPositions = [
  { position: 0, name: "" },
  { position: 165, name: "" },
  { position: 330, name: "" },
  { position: 495, name: "" },
  { position: 660, name: "" },
  { position: 825, name: "" },
  { position: 990, name: "" },
  { position: 1155, name: "" },
  { position: 1320, name: "" },
];

export function positions(state = defaultPositions, action) {
  switch (action.type) {
    case actions.SET_POSITION: {
      const newState = [...state];
      if (action.payload.runner === 8) {
        newState[action.payload.runner] = {
          ...state[action.payload.runner],
          position: Math.max(0, 1500 - action.payload.length),
          name: action.payload.name,
        };
        for (let i = action.payload.runner - 1; i >= 0; i--) {
          newState[i] = {
            ...newState[i],
            position: Math.min(
              newState[i].position,
              newState[action.payload.runner].position
            ),
          };
        }
        return newState;
      } else {
        const oldLength =
          (state[action.payload.runner + 1].position || 1500) -
          state[action.payload.runner].position;
        newState[action.payload.runner] = {
          ...state[action.payload.runner],
          name: action.payload.name,
        };
        for (let i = action.payload.runner + 1; i < 9; i++) {
          newState[i] = {
            ...newState[i],
            position: Math.min(
              1500,
              newState[i].position + action.payload.length - oldLength
            ),
          };
        }
        return newState;
      }
    }
    default:
      return state;
  }
}

const defaultSquadronPositions = [
  { position: 0, name: "" },
  { position: 165, name: "" },
  { position: 330, name: "" },
  { position: 495, name: "" },
  { position: 660, name: "" },
  { position: 830, name: "" },
];

export function squadronPositions(state = defaultSquadronPositions, action) {
  switch (action.type) {
    case actions.SET_POSITION: {
      const newState = [...state];
      if (action.payload.runner === 5) {
        newState[action.payload.runner] = {
          ...state[action.payload.runner],
          position: Math.max(0, 1000 - action.payload.length),
          name: action.payload.name,
        };
        for (let i = action.payload.runner - 1; i >= 0; i--) {
          newState[i] = {
            ...newState[i],
            position: Math.min(
              newState[i].position,
              newState[action.payload.runner].position
            ),
          };
        }
        return newState;
      } else if (action.payload.runner < 5) {
        const oldLength =
          (state[action.payload.runner + 1].position || 1000) -
          state[action.payload.runner].position;
        newState[action.payload.runner] = {
          ...state[action.payload.runner],
          name: action.payload.name,
        };
        for (let i = action.payload.runner + 1; i < 6; i++) {
          newState[i] = {
            ...newState[i],
            position: Math.min(
              1000,
              newState[i].position + action.payload.length - oldLength
            ),
          };
        }
        return newState;
      }
      return state;
    }
    default:
      return state;
  }
}
