import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import RunnerPanel from './RunnerPanel'
import { getErrorById } from '../../errors/util'
import * as errors from '../../errors'

const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingBottom: 24
  },
  wrapper: {
    margin: 8,
    marginTop: 16
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
})

const sheets = [{
  sheet: 'gfMe',
  title: 'Gruppenführer/Melder'
}, {
  sheet: 'ma',
  title: 'Maschinist'
}, {
  sheet: 'at',
  title: 'Angriffstrupp'
}, {
  sheet: 'wt',
  title: 'Wassertrupp'
}, {
  sheet: 'st',
  title: 'Schlauchtrupp'
}]

class RatingAccordion extends React.PureComponent {
  render () {
    const {
      classes,
      mistakes,
      onToggleMistake,
      group,
      hideClothErrors,
      hideFastCouplingErrors,
      waterSource,
      ...other
    } = this.props

    return (
      <div className={classes.root} {...other}>
        <div className={classes.wrapper}>
          {sheets.map(({ sheet, title }) => (
            <RunnerPanel
              key={sheet}
              sheet={sheet}
              title={title}
              hideClothErrors={hideClothErrors}
              hideFastCouplingErrors={hideFastCouplingErrors}
              waterSource={waterSource}
              mistakes={errors[waterSource][sheet]}
              checkedMistakes={mistakes}
              onToggleMistake={onToggleMistake}
            />
          ))}
        </div>
      </div>
    )
  }
}

RatingAccordion.propTypes = {
  mistakes: PropTypes.object.isRequired,
  onToggleMistake: PropTypes.func.isRequired,
  waterSource: PropTypes.oneOf(['og', 'uh']).isRequired,
  hideClothErrors: PropTypes.bool,
  hideFastCouplingErrors: PropTypes.bool
}

export default withStyles(styles)(RatingAccordion)

export function getErrorPoints (role, checkedMistakes) {
  let points = 0
  for (const errorId of Object.keys(checkedMistakes[role])) {
    const times = checkedMistakes[role][errorId] || 0
    if (times > 0) {
      points += getErrorById(errorId).points * times
    }
  }
  return points
}

export function getTotalErrorPoints (mistakes) {
  let points = 0
  for (const role of Object.keys(mistakes)) {
    points += getErrorPoints(role, mistakes)
  }
  return points
}
