export const SET_WATERSOURCE = 'settings/SET_WATERSOURCE'
export const SET_TIME_SORTING = 'settings/SET_TIME_SORTING'
export const SET_HIDE_CLOTH_ERRORS = 'settings/SET_HIDE_CLOTH_ERRORS'
export const SET_HIDE_FAST_COUPLING_ERRORS = 'settings/SET_HIDE_FAST_COUPLING_ERRORS'
export const SET_LSP_GROUP_SIZE = 'settings/SET_LSP_GROUP_SIZE'

export function setWaterSource (waterSource) {
  return { type: SET_WATERSOURCE, payload: waterSource }
}

export function toggleTimeSorting (enabled) {
  return { type: SET_TIME_SORTING, payload: enabled }
}

export function toggleClothErrors (enabled) {
  return { type: SET_HIDE_CLOTH_ERRORS, payload: enabled }
}

export function toggleFastCouplingErrors (enabled) {
  return { type: SET_HIDE_FAST_COUPLING_ERRORS, payload: enabled }
}

export function setLspGroupSize (size) {
  return { type: SET_LSP_GROUP_SIZE, payload: size }
}
