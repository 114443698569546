export const TOGGLE_MISTAKE = "PartB/TOGGLE_MISTAKE";
export const START_TIMER = "PartB/START_TIMER";
export const STOP_TIMER = "PartB/STOP_TIMER";
export const RESET_TIMER = "PartB/RESET_TIMER";
export const UNRESET_TIMER = "PartB/UNRESET_TIMER";
export const RESUME_TIMER = "PartB/RESUME_TIMER";
export const RESET_RESULTS = "PartB/RESET_RESULTS";
export const MERGE_RESULTS = "PartB/MERGE_RESULTS";

export function toggleMistake(runner, mistake) {
  return { type: TOGGLE_MISTAKE, payload: { runner, mistake } };
}

export function startTimer(time = new Date()) {
  return { type: START_TIMER, payload: time.toISOString() };
}

export function stopTimer(time = new Date()) {
  return { type: STOP_TIMER, payload: time.toISOString() };
}

export function resumeTimer() {
  return { type: RESUME_TIMER };
}

export function resetTimer() {
  return { type: RESET_TIMER };
}

export function unresetTimer() {
  return { type: UNRESET_TIMER };
}

export function resetResults() {
  return { type: RESET_RESULTS };
}

export function mergeResults(results) {
  return { type: MERGE_RESULTS, payload: results };
}
