import * as actions from "./actions";

export function timer(
  state = {
    active: false,
    startedAt: null,
    stoppedAt: null,
    time: null,
  },
  action
) {
  switch (action.type) {
    case actions.START_TIMER:
      return {
        ...state,
        active: true,
        startedAt: action.payload,
        stoppedAt: null,
        time: null,
      };
    case actions.STOP_TIMER:
      return {
        ...state,
        active: false,
        stoppedAt: action.payload,
        time: Date.parse(action.payload) - Date.parse(state.startedAt),
      };
    case actions.RESUME_TIMER:
      return {
        ...state,
        active: true,
        stoppedAt: null,
      };
    case actions.RESET_TIMER:
      return {
        ...state,
        previousStartedAt: state.startedAt,
        previousStoppedAt: state.stoppedAt,
        startedAt: null,
        stoppedAt: null,
      };
    case actions.UNRESET_TIMER:
      return {
        ...state,
        startedAt: state.previousStartedAt,
        stoppedAt: state.previousStoppedAt,
      };
    case actions.RESET_RESULTS:
      return {
        ...state,
        active: false,
        startedAt: null,
        stoppedAt: null,
        time: null,
      };
    default:
      return state;
  }
}
