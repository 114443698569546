import React from 'react'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import { formatTime } from '../../util/time'

const styles = (theme) => ({
  root: {
    padding: 8
  },
  paper: {
    padding: 16,
    marginBottom: 8,
    background: theme.palette.secondary.main,
    color: '#fff'
  },
  results: {
    width: '100%',
    fontSize: '15px',
    borderCollapse: 'collapse',
    '& tr > td:last-child': {
      textAlign: 'right'
    }
  },
  bold: {
    fontWeight: 700
  },
  spacing: {
    height: 8
  },
  spacingLine: {
    height: 8,
    '& > td': {
      borderBottom: '1px solid #fff'
    }
  }
})

function getPoints (time, groupSize) {
  if (groupSize === 9) {
    if (time <= 3 * 60 + 30) {
      return 4
    }
    if (time <= 3 * 60 + 45) {
      return 3
    }
    if (time <= 4 * 60) {
      return 2
    }
    if (time <= 4 * 60 + 15) {
      return 1
    }
    return 0
  } else if (groupSize === 6) {
    if (time <= 2 * 60 + 20) {
      return 4
    }
    if (time <= 2 * 60 + 30) {
      return 3
    }
    if (time <= 2 * 60 + 40) {
      return 2
    }
    if (time <= 2 * 60 + 50) {
      return 1
    }
    return 0
  }
}

function formatSpeed (speed) {
  return speed.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })
}

function Results (props) {
  const {
    classes,
    time,
    runnerTimes,
    lengths,
    groupSize,
    ...other
  } = props

  return (
    <div {...other} className={classes.root}>
      <Paper className={classes.paper}>
        <table className={classes.results}>
          <tbody>
            <tr><td>Gesamtzeit:</td><td colSpan={2}>{formatTime(time)}</td></tr>
            <tr className={classes.spacing} />
            {runnerTimes.length === groupSize && runnerTimes[groupSize - 1].end && runnerTimes.map(({ start, end }, i) => (
              <tr key={i}>
                <td>Läufer {i + 1}</td>
                <td>{formatSpeed(lengths[i].length / (end - start) * 3600)} km/h</td>
                <td>{formatTime(Math.round((end - start) / 1000))}</td>
              </tr>
            ))}
            <tr className={classes.spacingLine}><td colSpan={3} /></tr>
            <tr className={classes.spacing} />
            <tr className={classes.bold}><td>Punkte:</td><td colSpan={2}>{getPoints(time, groupSize)}</td></tr>
          </tbody>
        </table>
      </Paper>
    </div>
  )
}

export default withStyles(styles)(Results)
