import { connect } from "react-redux";
import Results from "../components/Results";

export default connect(
  (state) => ({
    mistakes: state.partA.mistakes,
    group: state.groupManagement.members.filter((m) => m.isInGroup),
    time:
      state.partA.timer.time != null
        ? Math.round(state.partA.timer.time / 1000)
        : null,
    knotsTime:
      state.partA.knotsTimer.time != null
        ? Math.round(state.partA.knotsTimer.time / 1000)
        : null,
    waterSource: state.settings.waterSource,
  }),
  () => ({})
)(Results);
