import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from 'mdi-material-ui/ChevronDown'
import SwipeableViews from 'react-swipeable-views'
import shallowCompare from 'shallow-compare'
import ErrorsTable from '../ErrorsTable'
import * as errors from '../../errors'
import * as roles from '../../roles'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  phase: {
    margin: 8,
    marginTop: 16,
    '&:first-child': {
      marginTop: -8
    }
  },
  phaseHeading: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  expansionPanel: {
    '&:first-of-type': {
      marginTop: 16
    }
  },
  expansionPanelDetails: {
    paddingLeft: 0,
    paddingRight: 0
  },
  tabsScroller: {
    '&::-webkit-scrollbar': { 
      display: 'none'
    }
  }
})

const swipeableViewStyles = {
  root: {
    height: 'calc(100% - 48px)'
  },
  container: {
    height: '100%'
  }
}

const Phase = withStyles(styles)(class extends React.Component {
  shouldComponentUpdate ({ phase, checkedMistakes, waterSource, ...other }) {
    if (this.props.checkedMistakes !== checkedMistakes) {
      for (const sheet of Object.values(errors[waterSource].phases[phase].errors)) {
        for (const role of sheet.roles) {
          if (this.props.checkedMistakes[role] === checkedMistakes[role]) {
            continue
          }
          for (const error of sheet.errors) {
            if (!Number.isInteger(error.points)) {
              for (const subError of error.points) {
                if (this.props.checkedMistakes[role][subError.id] !== checkedMistakes[role][subError.id]) {
                  return true
                }
              }
            } else if (this.props.checkedMistakes[role][error.id] !== checkedMistakes[role][error.id]) {
              return true
            }
          }
        }
      }
    }

    return shallowCompare(this, other)
  }

  render () {
    const {
      classes,
      errors,
      checkedMistakes,
      onToggleMistake,
      hideClothErrors,
      hideFastCouplingErrors,
      waterSource
    } = this.props

    return (
      <div className={classes.phase}>
        {Object.keys(errors).map((sheet) => (
          <PhaseRole
            key={sheet}
            sheet={sheet}
            errors={errors}
            onToggleMistake={onToggleMistake}
            checkedMistakes={checkedMistakes}
            hideClothErrors={hideClothErrors}
            hideFastCouplingErrors={hideFastCouplingErrors}
            waterSource={waterSource}
          />
        ))}
      </div>
    )
  }
})

const PhaseRole = withStyles(styles)(class extends React.Component {
  shouldComponentUpdate ({ sheet, checkedMistakes, waterSource, ...other }) {
    // only update if there are new errors that affect this phase's roles
    if (sheet !== this.props.sheet) return true
    if (waterSource !== this.props.waterSource) return true
    for (const role of errors[waterSource][sheet].roles) {
      if (checkedMistakes[role] !== this.props.checkedMistakes[role]) {
        return true
      }
    }

    // shallow-compare other props
    for (const key of Object.keys(other)) {
      if (this.props[key] !== other[key]) return true
    }

    return false
  }

  render () {
    const {
      classes,
      checkedMistakes,
      errors,
      onToggleMistake,
      sheet,
      hideClothErrors,
      hideFastCouplingErrors
    } = this.props

    const mistakes = {
      ...errors[sheet],
      errors: errors[sheet].errors
        .filter((mistake) => !hideClothErrors || mistake.type !== 'clothes')
        .filter((mistake) => !hideFastCouplingErrors || mistake.type !== 'fastCoupling')
    }

    if (mistakes.errors.length === 0) return null

    return (
      <ExpansionPanel
        className={classes.expansionPanel}
        defaultExpanded
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>{roles[sheet].long}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionPanelDetails}>
          <ErrorsTable
            checkedMistakes={checkedMistakes}
            mistakes={mistakes}
            onToggleMistake={onToggleMistake}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
})

const phases = {
  uh: errors.uh.phases.map((phase) => {
    const nonEmptySheets = {}
    for (const sheet of Object.keys(phase.errors)) {
      if (phase.errors[sheet].errors.length > 0) {
        nonEmptySheets[sheet] = phase.errors[sheet]
      }
    }
    return {
      ...phase,
      errors: nonEmptySheets
    }
  }),
  og: errors.og.phases.map((phase) => {
    const nonEmptySheets = {}
    for (const sheet of Object.keys(phase.errors)) {
      if (phase.errors[sheet].errors.length > 0) {
        nonEmptySheets[sheet] = phase.errors[sheet]
      }
    }
    return {
      ...phase,
      errors: nonEmptySheets
    }
  })
}

function PhaseRatingView (props) {
  const { classes, mistakes, onToggleMistake, group, time, knotsTime, hideClothErrors, hideFastCouplingErrors, waterSource, ...other } = props
  const [index, setIndex] = React.useState(0);

  return (
    <div className={classes.root} {...other}>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        value={index}
        onChange={(e, i) => setIndex(i)}
        classes={{ scroller: classes.tabsScroller }}
      >
        <Tab label="Beginn" />
        <Tab label="1. Rohr" />
        <Tab label="2. Rohr" />
        <Tab label="3. Rohr" />
        <Tab label="Ende" />
      </Tabs>
      <SwipeableViews
        containerStyle={swipeableViewStyles.container}
        style={swipeableViewStyles.root}
        index={index}
        onChangeIndex={(i) => setIndex(i)}
        ignoreNativeScroll // filter out false positive that blocks the swipe
      >
        {phases[waterSource].map(({ name, errors }, i) => (
          <Phase
            key={i}
            name={name}
            phase={i}
            errors={errors}
            checkedMistakes={mistakes}
            onToggleMistake={onToggleMistake}
            hideClothErrors={hideClothErrors}
            hideFastCouplingErrors={hideFastCouplingErrors}
            waterSource={waterSource}
          />
        ))}
      </SwipeableViews>
    </div>
  )
}

PhaseRatingView.propTypes = {
  mistakes: PropTypes.object.isRequired,
  onToggleMistake: PropTypes.func.isRequired,
  waterSource: PropTypes.oneOf(['og', 'uh']).isRequired,
  hideClothErrors: PropTypes.bool,
  hideFastCouplingErrors: PropTypes.bool
}

export default withStyles(styles)(PhaseRatingView)
