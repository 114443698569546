import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = {
  content: {
    display: 'flex',
    flexDirection:'column',
    alignItems: 'stretch'
  },
  name: {
    marginBottom: 8 
  },
}

class EditLengthDialog extends Component {
  state = {}

  static getDerivedStateFromProps (props, state) {
    if (props.runner !== state.runner) {
      return { length: props.defaultLength, name: props.defaultName, runner: props.runner }
    }
    return false
  }

  handleChangeLength = (e) => {
    this.setState({ length: e.target.value })
  }

  handleChangeName = (e) => {
    this.setState({ name: e.target.value })
  }

  handleSubmit = () => {
    this.props.onSubmit({
      length: parseInt(this.state.length, 10) || 0,
      name: this.state.name
    })
  }

  handleCancel = () => {
    this.props.onClose()
  }

  render () {
    const {
      classes,
      onSubmit,
      runner,
      defaultLength,
      defaultName,
      ...other
    } = this.props

    const {
      length,
      name
    } = this.state

    return (
      <Dialog {...other}>
        <DialogTitle>Läufer {runner + 1}</DialogTitle>
        <DialogContent className={classes.content}>
          <TextField
            type='number'
            label='Strecke'
            id='length'
            value={length}
            onChange={this.handleChangeLength}
            className={classes.name}
            autoComplete='off'
            autoFocus
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">m</InputAdornment>
              )
            }}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                this.handleSubmit()
              }
            }}
            onFocus={(e) => e.target.select()}
          />
          <TextField
            label='Name'
            id='name'
            value={name}
            onChange={this.handleChangeName}
            className={classes.name}
            autoComplete='off'
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                this.handleSubmit()
              }
            }}
            onFocus={(e) => e.target.select()}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleCancel}
          >
            Abbrechen
          </Button>
          <Button
            onClick={this.handleSubmit}
            color='primary'
          >
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(EditLengthDialog)
