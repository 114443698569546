import { connect } from "react-redux";
import Results from "../components/Results";

export default connect(
  (state) => ({
    mistakes: state.partB.mistakes,
    group: state.groupManagement.members.filter((m) => m.isInGroup),
    time:
      state.partB.timer.time != null
        ? Math.round(state.partB.timer.time / 1000)
        : null,
  }),
  () => ({})
)(Results);
