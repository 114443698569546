let errorId = 1

function error (description, points) {
  return {
    id: errorId++,
    description,
    points
  }
}

const clothes = error('Mängel in der persönlichen Ausrüstung', [
  error('kein DJF-Übungsanzug', 10),
  error('kein DJF-Schutzhelm', 10),
  error('keine Sportschuhe gemäß Ausschreibung', 10),
  error('keine Schutzhandschuhe', 10),
  error('kein Brusttuch Nummer __NUMBER__', 5)
])
clothes.type = 'clothes'
const baton = error('Staffelstab nicht korrekt übernommen', 10)
const withoutBaton = error('Arbeiten vor Staffelstab-Übergabe', 50)

export default [
  [clothes],
  [clothes, baton],
  [clothes, withoutBaton, baton,
    error('C-Druckschlauch nicht einfach gerollt', 50),
    error('Gerollten C-Druckschlauch nicht ordnungsgemäß abgelegt (Kupplung nicht am Schlauch etc.)', 5)
  ],
  [clothes, baton,
    error('Laufbrett nicht vollständig überlaufen', 5),
    error('Laufbrett seitlich verlassen', 5),
    error('Laufbrett ausgelassen', 10)
  ],
  [
    {
      ...clothes,
      id: errorId++,
      points: [
        clothes.points[0],
        error('kein Schmalgurt mit Zweidornschalle', 10),
        ...clothes.points.slice(1)
      ]
    },
    error('Läufer 5 liegt nicht ordnungsgemäß auf der Krankentrage', 10),
    error('Arbeiten vor Ablage des Staffelstabes', 50),
    error('Staffelstab von Läufer 4 nicht auf die Schutzhandschuhe von Läufer 5 gelegt', 10),
    error('Läufer 5 verlässt ohne vollständig angelegte Schutzausrüstung den markierten Bereich', 20),
    error('Läufer 5 verlässt ohne angelegte Schutzausrüstung den markierten Bereich', 50)
  ],
  [clothes, baton],
  [clothes, withoutBaton, baton,
    error('Läufer 7 hat mit Läufer 8 nicht ordnungsgemäß gekuppelt', 5),
    error('Beim Kuppeln übergegriffen', 5)
  ],
  [clothes, withoutBaton, baton,
    error('Läufer 8 hat mit Läufer 7 nicht ordnungsgemäß gekuppelt', 5),
    error('Beim Kuppeln übergegriffen', 5),
    error('Doppelter Ankerstich und halber Schlag falsch ausgeführt', [
      error('Leinenbeutel nicht am Schlauch', 5),
      error('doppelter Ankerstich nicht über den Kupplungen oder falsch ausgeführt', 5),
      error('halber Schlag nicht am Mundstück oder falsch ausgeführt', 5)
    ]),
    error('Doppelter Ankerstich und halber Schlag nicht ausgeführt', 20)
  ],
  [clothes, withoutBaton, baton,
    error('Läufer 9 übertritt beim Werfen die 392-m-Markierung', 5),
    error('Leinenende liegt bei Übungsende nicht vor der 392-m-Markierung', 5),
    error('Feuerwehrleine liegt bei Übungsende nicht über der Ziellinie bzw. liegt außerhalb der Stangen', 10),
    error('Feuerwehrleine nicht in der vorgesehenen Form geworfen', 50)
  ]
]
