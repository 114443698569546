import React from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from 'mdi-material-ui/ChevronDown'
import cx from 'classnames'
import { getErrorPoints } from './RatingAccordion'

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  expansionPanelDetails: {
    paddingLeft: 0,
    paddingRight: 0
  },
  mistakes: {
    width: '100%',
    fontSize: theme.typography.pxToRem(15),
    borderCollapse: 'collapse',
    '& tr > td:first-child': {
      paddingLeft: 24
    },
    '& tr$nestedMistake > td:first-child': {
      paddingLeft: 40
    },
    '& tr > td:last-child': {
      paddingRight: 24
    }
  },
  nestedMistake: {
  },
  points: {
    textAlign: 'right',
    paddingLeft: 8
  },
  pointsButton: {
    minWidth: 32,
    minHeight: 32,
    borderRadius: '50%',
    color: '#000 !important',
    lineHeight: 'initial' // fix number not being vertically centered
  },
  activePointsButton: {
    border: `2px solid ${theme.palette.primary.main}`,
    padding: '5px 6px'
  }
})

class RunnerPanel extends React.Component {
  handleChange = (event, expanded) => {
    if (this.props.onChange) {
      this.props.onChange(event, this.props.runner, expanded)
    }
  }

  render () {
    const {
      classes,
      mistakes,
      checkedMistakes,
      onChange,
      onToggleMistake,
      runner,
      hideClothErrors,
      ...other
    } = this.props

    return (
      <ExpansionPanel onChange={this.handleChange} {...other}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Läufer {runner + 1}</Typography>
          <Typography className={classes.secondaryHeading}>{getErrorPoints(runner, checkedMistakes)} Fehlerpunkte</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionPanelDetails}>
          <table className={classes.mistakes}>
            <tbody>
              {mistakes.filter((mistake) => !hideClothErrors || mistake.type !== 'clothes').map((mistake) => (
                <React.Fragment key={mistake.id}>
                  <tr className={classes.mistake}>
                    <td>{mistake.description}</td>
                    {Number.isInteger(mistake.points) ? (
                      <td className={classes.points}>
                        <Button
                          size='small'
                          className={cx(classes.pointsButton, {[classes.activePointsButton]: checkedMistakes[mistake.id] === true})}
                          onClick={onToggleMistake ? () => onToggleMistake(runner + 1, mistake.id) : null}
                          disabled={onToggleMistake == null}
                        >
                          {mistake.points}
                        </Button>
                      </td>
                    ) : <td />}
                  </tr>
                  {!Number.isInteger(mistake.points) && mistake.points.map((mistake) => (
                    <tr key={mistake.id} className={classes.nestedMistake}>
                      <td>{mistake.description.replace(/__NUMBER__/g, runner + 1)}</td>
                      <td className={classes.points}>
                        <Button
                          size='small'
                          className={cx(classes.pointsButton, {[classes.activePointsButton]: checkedMistakes[mistake.id] === true})}
                          onClick={onToggleMistake ? () => onToggleMistake(runner + 1, mistake.id) : null}
                          disabled={onToggleMistake == null}
                        >
                          {mistake.points}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

export default withStyles(styles)(RunnerPanel)
