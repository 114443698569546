import { connect } from 'react-redux'
import Settings from '../components/Settings'
import { setWaterSource, toggleTimeSorting, toggleClothErrors, toggleFastCouplingErrors, setLspGroupSize } from '../actions'

export default connect(
  (state) => ({
    timeSortingEnabled: state.settings.timeSorting,
    waterSource: state.settings.waterSource,
    hideClothErrors: state.settings.hideClothErrors,
    hideFastCouplingErrors: state.settings.hideFastCouplingErrors,
    groupSize: state.settings.lspGroupSize
  }),
  {
    onToggleTimeSorting: toggleTimeSorting,
    onChangeWaterSource: setWaterSource,
    onToggleClothErrors: toggleClothErrors,
    onToggleFastCouplingErrors: toggleFastCouplingErrors,
    onChangeGroupSize: setLspGroupSize
  }
)(Settings)
