/* global localStorage */
import { combineReducers, createStore } from "redux";
import { tryParse } from "./modules/util/json";
import * as reducers from "./reducers";

export default function configureStore() {
  const store = createStore(
    combineReducers(reducers),
    {
      partA: tryParse(localStorage.getItem("partA")),
      partB: tryParse(localStorage.getItem("partB")),
      relayRace: {
        positions: tryParse(localStorage.getItem("positions")),
        squadronPositions: tryParse(localStorage.getItem("squadronPositions")),
        ...tryParse(localStorage.getItem("relayRace"), {}),
      },
      shotPut: tryParse(localStorage.getItem("shotPut")),
      speedExercise: tryParse(localStorage.getItem("speedExercise")),
    },
    process.env.NODE_ENV !== "production" && window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : undefined
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./reducers", () => {
      store.replaceReducer(reducers);
    });
  }

  const state = store.getState();
  let oldGroupManagement = state.groupManagement;
  let oldSettings = state.settings;
  let oldPage = state.app.selectedPage;
  let oldPartA = state.partA;
  let oldPartB = state.partB;
  let oldRelayRace = state.relayRace;
  let oldShotPut = state.shotPut;
  let oldSpeedExercise = state.seedExercise;

  store.subscribe(() => {
    const {
      settings,
      app,
      partA,
      partB,
      groupManagement,
      relayRace,
      shotPut,
      speedExercise,
    } = store.getState();

    if (groupManagement !== oldGroupManagement) {
      localStorage.setItem("groupManagement", JSON.stringify(groupManagement));
      oldGroupManagement = groupManagement;
    }
    if (settings !== oldSettings) {
      localStorage.setItem("settings", JSON.stringify(settings));
      oldSettings = settings;
    }
    if (app.selectedPage !== oldPage) {
      localStorage.setItem("selectedPage", app.selectedPage);
      oldPage = app.selectedPage;
    }
    if (partA !== oldPartA) {
      localStorage.setItem("partA", JSON.stringify(partA));
    }
    if (partB !== oldPartB) {
      localStorage.setItem("partB", JSON.stringify(partB));
    }
    if (relayRace !== oldRelayRace) {
      localStorage.setItem("relayRace", JSON.stringify(relayRace));
    }
    if (shotPut !== oldShotPut) {
      localStorage.setItem("shotPut", JSON.stringify(shotPut));
    }
    if (speedExercise !== oldSpeedExercise) {
      localStorage.setItem("speedExercise", JSON.stringify(speedExercise));
    }
  });

  return store;
}
