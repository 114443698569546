let errorId = 1
const errorsById = {}

export function error (description, points, phase = null, appliesTo = null) {
  const id = errorId++
  errorsById[id] = {
    id,
    phase,
    description,
    points,
    appliesTo: appliesTo != null ? appliesTo.split(',') : null
  }
  return errorsById[id]
}

export function multiError (description, points, phase = null, appliesTo = null, multiple = 'je Fall') {
  const id = errorId++
  errorsById[id] = {
    id,
    phase,
    description,
    points,
    appliesTo: appliesTo != null ? appliesTo.split(',') : null,
    multiple
  }
  return errorsById[id]
}

export function getErrorById (id) {
  return errorsById[id]
}

export function filterPhaseErrors (errors, phase) {
  return errors
    .filter((error) => error.phase == null || error.phase === phase || (Array.isArray(error.phase) && error.phase.indexOf(phase) >= 0))
    .map((error) => {
      if (Array.isArray(error.points)) {
        return {
          ...error,
          points: filterPhaseErrors(error.points, phase)
        }
      }
      return error
    })
}
