import React from 'react'
import QRCode from 'qrcode.react'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import BackIcon from 'mdi-material-ui/ArrowLeft'
import AppBar from './AppBar'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  help: {
    margin: '24px 24px 0',
    textAlign: 'center'
  },
}

class DataSender extends React.PureComponent {
  state = {
    frame: null
  }

  componentDidMount() {
    const frames = JSON.stringify(this.props.data).match(/.{1,50}/g)
        .map((frame, i, array) => `${i < 10 ? `0${i}` : i}${array.length < 10 ? `0${array.length}` : array.length}${frame.padEnd(50)}`)
    let i = 0
    this._interval = setInterval(() => {
      i++
      this.setState(() => ({ frame: frames[(i + 1) % frames.length] }))
    }, 500)
  }

  componentWillUnmount() {
    clearInterval(this._interval)
  }

  render () {
    const {
      classes,
      onClose
    } = this.props

    const {
      frame
    } = this.state

    return (
      <div className={classes.root}>
        <AppBar
          title='Daten senden'
          leftIcon={BackIcon}
          onButtonClick={onClose}
        />
        <Typography className={classes.help}>
          Die Daten werden optisch übermittelt. Starte den Datenempfang auf dem Zielgerät und richte die Kamera auf die Grafik.
        </Typography>
        {frame && <QRCode
        value={frame}
        style={{ flex: 1, padding: 50, width: 'calc(100% - 100px)' }}
        renderAs='svg'
        />}
    </div>
    )
  }
}

export default withStyles(styles)(DataSender)
