import { useSnackbar } from "material-ui-snackbar-provider";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import useTimer from "../../../hooks/useTimer";
import TimerComponent from "../components/Timer";
import { getTime } from "../util";
import {
  resetKnotsTimer,
  resetTimer,
  resumeKnotsTimer,
  resumeTimer,
  startKnotsTimer,
  startTimer,
  stopKnotsTimer,
  stopTimer,
  unresetTimer,
  unresetKnotsTimer,
} from "../actions";

export default function Timer(props) {
  const waterSource = useSelector((state) => state.settings.waterSource);
  const totalTimer = useTimer((state) => state.partA.timer, {
    startTimer,
    stopTimer,
    resumeTimer,
    resetTimer,
    unresetTimer,
  });
  const knotsTimer = useTimer((state) => state.partA.knotsTimer, {
    startTimer: startKnotsTimer,
    stopTimer: stopKnotsTimer,
    resumeTimer: resumeKnotsTimer,
    resetTimer: resetKnotsTimer,
    unresetTimer: unresetKnotsTimer,
  });
  const snackbar = useSnackbar();

  const handleToggleTimer = useCallback(() => {
    if (totalTimer.active) {
      if (knotsTimer.active) {
        const now = new Date();
        totalTimer.stopTimer(now);
        knotsTimer.stopTimer(now);
        snackbar.showMessage("Stoppuhr angehalten", "Rückgängig", () => {
          totalTimer.resumeTimer();
          knotsTimer.resumeTimer();
        });
      } else {
        knotsTimer.startTimer();
        snackbar.showMessage(
          "Zeittakt für Knoten gestartet",
          "Rückgängig",
          () => {
            knotsTimer.resetTimer();
          }
        );
      }
    } else if (totalTimer.time > 0) {
      totalTimer.resetTimer();
      knotsTimer.resetTimer();
      snackbar.showMessage("Stoppuhr zurückgesetzt", "Rückgängig", () => {
        totalTimer.unresetTimer();
        knotsTimer.unresetTimer();
      });
    } else {
      totalTimer.startTimer();
      knotsTimer.resetTimer();
      snackbar.showMessage("Stoppuhr gestartet");
    }
  }, [knotsTimer, totalTimer, snackbar]);

  return (
    <TimerComponent
      {...props}
      seconds={Math.round(totalTimer.time / 1000)}
      maximumSeconds={getTime(waterSource)}
      knotsSeconds={
        knotsTimer.time > 0 ? Math.round(knotsTimer.time / 1000) : null
      }
      onClick={handleToggleTimer}
    />
  );
}
