export const ADD_MEMBER = 'ADD_MEMBER'
export const REMOVE_MEMBER = 'REMOVE_MEMBER'
export const TOGGLE_IS_IN_GROUP = 'TOGGLE_IS_IN_GROUP'

export function addMember ({ name, yearOfBirth }) {
  return {
    type: ADD_MEMBER,
    payload: {
      id: `m_${Date.now()}`,
      name,
      yearOfBirth
    }
  }
}

export function removeMember (id) {
  return { type: REMOVE_MEMBER, payload: id }
}

export function toggleIsInGroup (id) {
  return { type: TOGGLE_IS_IN_GROUP, payload: id }
}
