import { connect } from 'react-redux'
import * as actions from '../actions'
import Positions from '../components/Positions'

export default connect(
  (state) => ({
    positions: state.settings.lspGroupSize === 6 ? state.relayRace.squadronPositions : state.relayRace.positions,
    groupSize: state.settings.lspGroupSize
  }), {
    onRunnerUpdated: actions.setRunner
  })(Positions)
