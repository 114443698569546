import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function useTimer(
  selector,
  actions,
  { active } = { active: true }
) {
  const dispatch = useDispatch();
  const timerState = useSelector(selector);
  const startTimer = useCallback(
    (now = new Date()) => {
      dispatch(actions.startTimer(now));
    },
    [actions, dispatch]
  );

  const [time, setTime] = useState(
    timerState.active
      ? Date.now() - Date.parse(timerState.startedAt)
      : timerState.startedAt != null
      ? timerState.time ?? 0
      : 0
  );

  useEffect(() => {
    if (!timerState.active && timerState.time == null) {
      setTime(0);
    }
  }, [timerState.time, timerState.active]);

  useEffect(() => {
    if (timerState.active && active) {
      const interval = setInterval(() => {
        setTime(Date.now() - Date.parse(timerState.startedAt));
      }, 100);

      return () => {
        clearInterval(interval);
      };
    }
  }, [timerState.active, timerState.startedAt, active]);

  const resetTimer = useCallback(() => {
    setTime(0);
    dispatch(actions.resetTimer());
  }, [actions, dispatch]);

  const unresetTimer = useCallback(() => {
    setTime(
      Date.parse(timerState.stoppedAt) - Date.parse(timerState.startedAt)
    );
    dispatch(actions.unresetTimer());
  }, [actions, dispatch, timerState.startedAt, timerState.stoppedAt]);

  const resumeTimer = useCallback(() => {
    dispatch(actions.resumeTimer());
  }, [actions, dispatch]);

  const stopTimer = useCallback(
    (now = new Date()) => {
      setTime(now.getTime() - Date.parse(timerState.startedAt));
      dispatch(actions.stopTimer(now));
    },
    [actions, dispatch, timerState.startedAt]
  );

  return useMemo(
    () => ({
      active: timerState.active,
      time,
      startTimer,
      stopTimer,
      resumeTimer,
      resetTimer,
      unresetTimer,
    }),
    [
      timerState.active,
      time,
      startTimer,
      stopTimer,
      resumeTimer,
      resetTimer,
      unresetTimer,
    ]
  );
}
