import { connect } from 'react-redux'
import * as actions from '../actions'
import RatingAccordion from '../components/RatingAccordion'

export default connect(
  (state) => ({
    mistakes: state.partB.mistakes,
    group: state.groupManagement.members.filter((m) => m.isInGroup),
    time: state.partB.time,
    hideClothErrors: state.settings.hideClothErrors
  }),
  {
    onToggleMistake: actions.toggleMistake
  }
)(RatingAccordion)
