import { Chip, ListSubheader } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Drawer from "@material-ui/core/SwipeableDrawer";
import withStyles from "@material-ui/core/styles/withStyles";
import GroupIcon from "mdi-material-ui/AccountGroup";
import BookIcon from "mdi-material-ui/BookOpenPageVariant";
import CheckboxMultipleBlankCircleIcon from "mdi-material-ui/CheckboxMultipleBlankCircle";
import ClockFastIcon from "mdi-material-ui/ClockFast";
import FireTruckIcon from "mdi-material-ui/FireTruck";
import OpenInNewIcon from "mdi-material-ui/OpenInNew";
import RunFastIcon from "mdi-material-ui/RunFast";
import React from "react";
import useTimer from "../../../hooks/useTimer";
import { formatTime } from "../../util/time";

const styles = ({ palette }) => ({
  header: {
    background: `url(${require("./menu.jpg")}) #eee`,
    backgroundSize: "cover",
    backgroundPosition: "bottom left",
    position: "relative",
    "&:before": {
      display: "block",
      content: '""',
      width: "100%",
      paddingTop: "56.25%", // for 16:9
    },
  },
  menu: {
    width: 320,

    "@media (min-width: 600px, max-width: 959px)": {
      width: "calc(100vw - 64px)",
      maxWidth: 320, // matches most Google apps
    },

    "@media (max-width: 599px)": {
      width: "calc(100vw - 56px)",
      maxWidth: 320, // matches most Google apps
    },
  },
  itemText: {
    fontSize: "14px",
    color: palette.grey[900],
    fontWeight: 500,
  },
  listItem: {
    paddingTop: 12,
    paddingBottom: 12,
    "& h3": {
      fontWeight: 500,
    },
    "&$selectedItem": {
      backgroundColor: palette.grey[200],

      "& $itemText, & $icon": {
        color: palette.primary.main,
      },
    },
  },
  selectedItem: {},
  lightIcon: {
    fill: palette.text.hint,
  },
  chip: {
    "& span": {
      fontWeight: 500,
    },
  },
  icon: {}, // don't remove this, it's used above
});

const SimpleMenuItem = withStyles(styles)(
  ({ classes, name, Icon, route, selected, onChangeRoute, chip, ...other }) => (
    <MenuItem
      {...other}
      selected={selected}
      classes={{ root: classes.listItem, selected: classes.selectedItem }}
      button
      onClick={() => {
        onChangeRoute(route);
      }}
    >
      {Icon && (
        <ListItemIcon classes={{ root: classes.icon }}>
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText primary={name} classes={{ primary: classes.itemText }} />
      {chip && <Chip label={chip} className={classes.chip} />}
    </MenuItem>
  )
);

const LinkMenuItem = withStyles(styles)(({ classes, name, Icon, link }) => (
  <MenuItem
    onClick={() => window.open(link)}
    button
    classes={{ root: classes.listItem }}
  >
    <ListItemIcon classes={{ root: classes.icon }}>
      <Icon />
    </ListItemIcon>
    <ListItemText classes={{ primary: classes.itemText }} primary={name} />
    <ListItemIcon>
      <OpenInNewIcon className={classes.lightIcon} />
    </ListItemIcon>
  </MenuItem>
));

function NavigationDrawer({
  classes,
  selectedPage,
  onChangeRoute,
  open,
  onOpen,
  onClose,
  disableSwipeToOpen,
}) {
  const partATimer = useTimer((state) => state.partA.timer, undefined, {
    active: open,
  });
  const partBTimer = useTimer((state) => state.partB.timer, undefined, {
    active: open,
  });
  const relayRaceTimer = useTimer((state) => state.relayRace.timer, undefined, {
    active: open,
  });
  const speedExerciseTimer = useTimer(
    (state) => state.speedExercise.timer,
    undefined,
    {
      active: open,
    }
  );

  const bwbMenuItems = [
    {
      name: "A-Teil",
      chip: partATimer.active
        ? formatTime(Math.round(partATimer.time / 1000))
        : null,
      Icon: FireTruckIcon,
      route: "PartA",
    },
    {
      name: "B-Teil",
      chip: partBTimer.active
        ? formatTime(Math.round(partBTimer.time / 1000))
        : null,
      Icon: RunFastIcon,
      route: "PartB",
    },
    {
      name: "Übungsgruppe",
      Icon: GroupIcon,
      route: "Group",
    },
  ];

  const lspMenuItems = [
    {
      name: "Schnelligkeitsübung",
      chip: speedExerciseTimer.active
        ? formatTime(Math.round(speedExerciseTimer.time / 1000))
        : null,
      Icon: ClockFastIcon,
      route: "SpeedExercise",
    },
    {
      name: "Kugelstoßen",
      Icon: CheckboxMultipleBlankCircleIcon,
      route: "ShotPut",
    },
    {
      name: "Staffellauf",
      chip: relayRaceTimer.active
        ? formatTime(Math.round(relayRaceTimer.time / 1000))
        : null,
      Icon: RunFastIcon,
      route: "RelayRace",
    },
  ];

  return (
    <Drawer
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      disableSwipeToOpen={disableSwipeToOpen}
    >
      <List className={classes.menu}>
        <ListSubheader disableSticky>Bundeswettbewerb</ListSubheader>
        {bwbMenuItems.map((props, i) => (
          <SimpleMenuItem
            {...props}
            selected={selectedPage === props.route}
            onChangeRoute={onChangeRoute}
            key={i}
          />
        ))}
        <LinkMenuItem
          name="Wettbewerbsordnung"
          Icon={BookIcon}
          link="https://jugendfeuerwehr.de/fileadmin/Jugendfeuerwehr/06_Service/04_Downloadcenter/Dokumente/2013_djf_Wettbewerbsordnung_BWB.pdf"
        />
        <LinkMenuItem
          name="Aktuelles"
          Icon={BookIcon}
          link="https://jugendfeuerwehr.de/fileadmin/Jugendfeuerwehr/04_Schwerpunkte/10_Wettbewerbe/Dokumente/Aktuelles_BWB_2016.pdf"
        />
        <Divider />
        <ListSubheader disableSticky>Leistungsspange</ListSubheader>
        {lspMenuItems.map((props, i) => (
          <SimpleMenuItem
            {...props}
            selected={selectedPage === props.route}
            onChangeRoute={onChangeRoute}
            key={i}
          />
        ))}
        <LinkMenuItem
          name="Richtlinien"
          Icon={BookIcon}
          link="https://jugendfeuerwehr.de/fileadmin/Jugendfeuerwehr/04_Schwerpunkte/10_Wettbewerbe/Dokumente/Richtlinien_Leistungsspange_2024.pdf"
        />
        <LinkMenuItem
          name="Erläuterungen"
          Icon={BookIcon}
          link="https://jugendfeuerwehr.de/fileadmin/Jugendfeuerwehr/04_Schwerpunkte/10_Wettbewerbe/Dokumente/Durchfuehrungsbestimmungen_LSP_2024.pdf"
        />
        <LinkMenuItem
          name="Aktuelles"
          Icon={BookIcon}
          link="https://jugendfeuerwehr.de/fileadmin/Jugendfeuerwehr/04_Schwerpunkte/10_Wettbewerbe/Dokumente/Aktuelles_LSP_2024.pdf"
        />
        <Divider />
        <SimpleMenuItem
          name="Einstellungen"
          route="Settings"
          selected={selectedPage === "Settings"}
          onChangeRoute={onChangeRoute}
        />
      </List>
    </Drawer>
  );
}

export default withStyles(styles)(NavigationDrawer);
