import React from "react";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import { formatTime } from "../../util/time";
import { getMaximumSeconds } from "../util";
import { getTotalErrorPoints } from "./RatingAccordion/RatingAccordion";
import RunnerPanel from "./RatingAccordion/RunnerPanel";
import errors from "../errors";

const styles = (theme) => ({
  root: {
    padding: 8,
  },
  paper: {
    padding: 16,
    marginBottom: 8,
    background: theme.palette.secondary.main,
    color: "#fff",
  },
  results: {
    width: "100%",
    fontSize: "15px",
    borderCollapse: "collapse",
    "& tr > td:last-child": {
      textAlign: "right",
    },
  },
  bold: {
    fontWeight: 700,
  },
  spacing: {
    height: 8,
  },
  spacingLine: {
    height: 8,
    "& > td": {
      borderBottom: "1px solid #fff",
    },
  },
});

function getCheckedMistakes(errors, checkedMistakes) {
  const isChecked = (error) => checkedMistakes[error.id] === true;

  const checkedMistakesThisSheet = [];
  for (const error of errors) {
    if (isChecked(error)) {
      checkedMistakesThisSheet.push(error);
    } else if (
      !Number.isInteger(error.points) &&
      error.points.some(isChecked)
    ) {
      const checkedSubErrors = error.points.filter((subError) =>
        isChecked(subError)
      );
      if (checkedSubErrors.length > 0) {
        checkedMistakesThisSheet.push({
          ...error,
          points: checkedSubErrors,
        });
      }
    }
  }

  return checkedMistakesThisSheet;
}

function Results(props) {
  const { classes, group, time, mistakes, ...other } = props;

  const errorPoints = getTotalErrorPoints(mistakes);
  const maximumTime = getMaximumSeconds(group);
  const totalPoints =
    time > 0 && maximumTime != null
      ? 400 - errorPoints + maximumTime - time
      : null;

  return (
    <div {...other} className={classes.root}>
      <Paper className={classes.paper}>
        <table className={classes.results}>
          <tbody>
            <tr>
              <td>Zeitvorgabe:</td>
              <td>{maximumTime != null ? formatTime(maximumTime) : "—"}</td>
            </tr>
            <tr>
              <td>Vorgabepunkte:</td>
              <td>400</td>
            </tr>
            <tr className={classes.spacing} />
            <tr>
              <td>Zeit:</td>
              <td>{time == null ? "—" : formatTime(time)}</td>
            </tr>
            <tr className={classes.spacing} />
            <tr>
              <td>Zeitpunkte:</td>
              <td>
                {time > 0 && maximumTime != null ? maximumTime - time : "—"}
              </td>
            </tr>
            <tr>
              <td>Fehlerpunkte:</td>
              <td>{errorPoints}</td>
            </tr>
            <tr className={classes.spacingLine}>
              <td colSpan={2} />
            </tr>
            <tr className={classes.spacing} />
            <tr className={classes.bold}>
              <td>Summe B-Teil:</td>
              <td>{totalPoints != null ? totalPoints : "—"}</td>
            </tr>
          </tbody>
        </table>
      </Paper>

      <div>
        {errors
          .reduce((sheets, sheet, i) => {
            const checkedMistakesThisSheet = getCheckedMistakes(
              sheet,
              mistakes[i]
            );
            if (checkedMistakesThisSheet.length === 0) {
              return sheets;
            }
            return [
              ...sheets,
              {
                runner: i,
                checkedMistakesThisSheet,
              },
            ];
          }, [])
          .map(({ runner, checkedMistakesThisSheet }) => (
            <RunnerPanel
              key={runner}
              defaultExpanded
              runner={runner}
              mistakes={checkedMistakesThisSheet}
              checkedMistakes={mistakes[runner]}
            />
          ))}
      </div>
    </div>
  );
}

export default withStyles(styles)(Results);
