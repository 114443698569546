export function getMaximumSeconds (group) {
  const year = new Date().getFullYear()
  const totalAge = group.reduce((age, { yearOfBirth }) => age + year - yearOfBirth, 0)

  // Zeiten gemäß 5.9 der Wettbewerbsordnung
  const minimumTime = 160 // 2:40
  const maximumSeconds = minimumTime - 5 * (Math.round(totalAge / group.length) - 10)

  return isNaN(maximumSeconds) ? null : maximumSeconds
}
