import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

export default class SelectListItem extends React.Component {
  state = {
    anchorEl: null
  }

  handleClickListItem = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleMenuItemClick = (event, index) => {
    this.setState({ anchorEl: null })
    this.props.onSelect(index)
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { label, options, selectedIndex } = this.props
    const { anchorEl } = this.state

    return (
      <React.Fragment>
        <ListItem
          button
          onClick={this.handleClickListItem}
        >
          <ListItemText
            primary={label}
            secondary={options[selectedIndex].label}
          />
        </ListItem>
        <Menu
          anchorEl={anchorEl}
          open={anchorEl != null}
          onClose={this.handleClose}
        >
          {options.map(({ label, ...other }, index) => (
            <MenuItem
              key={label}
              selected={index === selectedIndex}
              onClick={event => this.handleMenuItemClick(event, index)}
              {...other}
            >
              {label}
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    );
  }
}
