import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import DotsVertical from 'mdi-material-ui/DotsVertical'
import { SnackbarProvider, withSnackbar } from 'material-ui-snackbar-provider'
import BackButtonFullscreenDialog from '../../components/BackButtonFullscreenDialog'
import DataReceiver from '../../components/DataReceiver'
import DataSender from '../../components/DataSender'
import { AppBarLayout, AppBar } from '../AppFrame'
import BottomNavigation from './components/BottomNavigation'
import Timer from './containers/Timer'
import Rating from './containers/Rating'
import Results from './containers/Results'
import { mergeResults, resetResults } from './actions'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  navigation: {
    width: '100%',
    zIndex: 101
  },
  menuButton: {
    marginRight: -12
  },
  snackbar: {
    marginBottom: 56,
    zIndex: 100
  }
})

class PartA extends PureComponent {
  state = {
    activePage: 'timer',
    open: false,
    showReceiveDialog: false,
    showSendDialog: false
  }

  handleChangePage = (event, value) => {
    this.setState({ activePage: value })
  }

  handleOpenMenu = (e) => {
    this.setState({ open: true, anchorEl: e.currentTarget })
  }

  handleCloseMenu = () => {
    this.setState({ open: false, anchorEl: null })
  }

  handleResetResults = () => {
    this.handleCloseMenu()
    this.props.dispatch(resetResults())
  }

  handleShowSendDialog = () => {
    this.handleCloseMenu()
    this.setState({ showSendDialog: true })
  }

  handleHideSendDialog = () => this.setState({ showSendDialog: false })

  handleShowReceiveDialog = () => {
    this.handleCloseMenu()
    this.setState({ showReceiveDialog: true })
  }

  handleHideReceiveDialog = () => this.setState({ showReceiveDialog: false })

  handleReceiveMistakes = ({ type, mistakes }) => {
    if (type === 'a') {
      this.props.snackbar.showMessage('Übertragung abgeschlossen. Die Wertungsbögen für den A-Teil wurden aktualisiert.')
      this.props.dispatch(mergeResults(mistakes))
    } else {
      this.props.snackbar.showMessage('Die empfangenen Daten gehören nicht zum A-Teil.')
    }
    this.setState({ showReceiveDialog: false })
  }

  render () {
    const {
      classes,
      className,
      onOpenDrawer,
      dispatch,
      mistakes,
      ...other
    } = this.props

    const {
      activePage,
      open,
      anchorEl,
      showReceiveDialog,
      showSendDialog
    } = this.state

    return (
      <SnackbarProvider
        ButtonProps={{ color: 'primary' }}
        SnackbarProps={{ autoHideDuration: 4000, classes: { root: classes.snackbar } }}
      >
        <AppBarLayout
          appBarRenderer={(props) => (
            <AppBar
              {...props}
              title='A-Teil'
              onButtonClick={onOpenDrawer}
            >
              <IconButton
                onClick={this.handleOpenMenu}
                color='inherit'
                className={classes.menuButton}
              >
                <DotsVertical />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={this.handleCloseMenu}
              >
                <MenuItem onClick={this.handleResetResults}>
                  Wertungsbögen leeren
                </MenuItem>
                {!process.env.REACT_APP_ANDROID && (
                  <React.Fragment>
                    <MenuItem onClick={this.handleShowReceiveDialog}>
                      Daten empfangen
                    </MenuItem>
                    <MenuItem onClick={this.handleShowSendDialog}>
                      Daten senden
                    </MenuItem>
                  </React.Fragment>
                )}
              </Menu>
            </AppBar>
          )}
        >
          <div className={classes.root} {...other}>
            <Timer
              style={{
                display: activePage === 'timer' ? 'flex' : 'none',
                flex: 1
              }}
            />
            <Rating
              style={{
                display: activePage === 'rating' ? 'block' : 'none',
                flex: 1,
                overflow: 'auto'
              }}
            />
            <Results
              style={{
                display: activePage === 'results' ? 'block' : 'none',
                flex: 1,
                overflow: 'auto'
              }}
            />
            <BottomNavigation
              classes={{ root: classes.navigation }}
              activePage={activePage}
              onChangePage={this.handleChangePage}
            />
          </div>

          <BackButtonFullscreenDialog
            open={showReceiveDialog}
            onClose={this.handleHideReceiveDialog}
          >
            <DataReceiver
              onClose={this.handleHideReceiveDialog}
              onDataReceived={this.handleReceiveMistakes}
            />
          </BackButtonFullscreenDialog>
          <BackButtonFullscreenDialog
            open={showSendDialog}
            onClose={this.handleHideSendDialog}
          >
            <DataSender
              data={{ type: 'a', mistakes: this.props.mistakes }}
              onClose={this.handleHideSendDialog}
            />
          </BackButtonFullscreenDialog>
        </AppBarLayout>
      </SnackbarProvider>
    )
  }
}

export default connect((state) => ({
  mistakes: state.partA.mistakes
}))(withStyles(styles)(withSnackbar()(PartA)))
