import React, { Component } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Checkbox from '@material-ui/core/Checkbox'
import Paper from '@material-ui/core/Paper'
import ListSubheader from '@material-ui/core/ListSubheader'
import Divider from '@material-ui/core/Divider'
import withStyles from '@material-ui/core/styles/withStyles'
import Back from 'mdi-material-ui/ArrowLeft'
import SelectListItem from '../../../components/SelectListItem'
import { AppBarLayout, AppBar } from '../../AppFrame'

const styles = {
  switchListItem: {
    paddingRight: 64
  },
  subheader: {
    background: '#fff',
    lineHeight: "32px",
    marginTop: 16
  }
}

class Settings extends Component {
  handleChangeWaterSource = (index) => {
    if (index === 0) {
      this.props.onChangeWaterSource('uh')
    } else {
      this.props.onChangeWaterSource('og')
    }
  }

  handleChangeSorting = (index) => {
    this.props.onToggleTimeSorting(index === 1)
  }

  handleToggleClothErrors = () => {
    this.props.onToggleClothErrors(!this.props.hideClothErrors)
  }

  handleToggleFastCouplingErrors = () => {
    this.props.onToggleFastCouplingErrors(!this.props.hideFastCouplingErrors)
  }

  handleChangeGroupSize = (index) => {
    this.props.onChangeGroupSize(index === 0 ? 9 : 6)
  }

  render () {
    const {
      classes,
      onClose,
      waterSource,
      onChangeWaterSource,
      timeSortingEnabled,
      onToggleTimeSorting,
      hideClothErrors,
      onToggleClothErrors,
      hideFastCouplingErrors,
      onToggleFastCouplingErrors,
      groupSize,
      onChangeGroupSize,
      ...other
    } = this.props

    return (
      <AppBarLayout
        appBarRenderer={(props) => (
          <AppBar
            {...props}
            title='Einstellungen'
            leftIcon={Back}
            onButtonClick={onClose}
          />
        )}
      >
        <Paper {...other}>
          <List disablePadding>
            <ListSubheader color='primary' className={classes.subheader}>Bundeswettbewerb</ListSubheader>
            <SelectListItem
              label='Wasserentnahmestelle'
              options={[
                { label: 'Unterflurhydrant' },
                { label: 'Offenes Gewässer' }
              ]}
              selectedIndex={waterSource === 'uh' ? 0 : 1}
              onSelect={this.handleChangeWaterSource}
            />
            <Divider />
            <SelectListItem
              label='Fehler bei der Bewertung sortieren'
              options={[
                { label: 'Nach Trupp' },
                { label: 'Nach zeitlicher Reihenfolge und Trupp' }
              ]}
              selectedIndex={timeSortingEnabled ? 1 : 0}
              onSelect={this.handleChangeSorting}
            />
            <Divider />
            <ListItem
              button
              onClick={this.handleToggleClothErrors}
              className={classes.switchListItem}
            >
              <ListItemText
                primary='Kleidungs-Fehler anzeigen'
                secondary={hideClothErrors ? 'Fehler bezüglich der persönlichen Ausrüstung werden ausgeblendet' : 'Fehler bezüglich der persönlichen Ausrüstung werden angezeigt'}
              />
              <ListItemSecondaryAction>
                <Checkbox
                  onChange={this.handleToggleClothErrors}
                  checked={!hideClothErrors}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={this.handleToggleFastCouplingErrors}
              className={classes.switchListItem}
            >
              <ListItemText
                primary={'A-Saugschläuche haben Schnell\u00ADkupplungs\u00ADgriffe'}
                secondary={hideFastCouplingErrors ? 'Fehler für das Kuppeln von A-Saug\u00ADschläuchen ohne Kupplungs\u00ADschlüssel werden ausgeblendet' : 'Fehler für das Kuppeln von A-Saug\u00ADschläuchen ohne Kupplungs\u00ADschlüssel werden angezeigt'}
              />
              <ListItemSecondaryAction>
                <Checkbox
                  onChange={this.handleToggleFastCouplingErrors}
                  checked={hideFastCouplingErrors}
                />
              </ListItemSecondaryAction>
            </ListItem>

            <ListSubheader color='primary' className={classes.subheader}>Leistungsspange</ListSubheader>
            <SelectListItem
              label='Stärke'
              options={[
                { label: 'Gruppe' },
                { label: 'Staffel' }
              ]}
              selectedIndex={groupSize === 6 ? 1 : 0}
              onSelect={this.handleChangeGroupSize}
            />

            <ListSubheader color='primary' className={classes.subheader}>Über Pocket JF</ListSubheader>
            <ListItem>
              <ListItemText
                primary={`Version ${
                  process.env.REACT_APP_VERSION
                }-${process.env.REACT_APP_GIT_SHA.substr(0, 7)}`}
                secondary="© 2018-2024 Maik Marschner"
              />
            </ListItem>
            {!process.env.REACT_APP_ANDROID && (
              <React.Fragment>
                <Divider />
                <ListItem button onClick={() => window.open('https://www.paypal.me/MaikMarschner/2')}>
                  <ListItemText
                    primary='Entwickler unterstützen'
                    secondary='Sichere meine Kaffeeversorgung und damit die Weiterentwicklung'
                  />
                </ListItem>
                <Divider />
                <ListItem button onClick={() => window.open('http://lemaik.de/impressum.html')}>
                  <ListItemText
                    primary='Impressum'
                  />
                </ListItem>
              </React.Fragment>
            )}
          </List>
        </Paper>
      </AppBarLayout>
    )
  }
}

export default withStyles(styles)(Settings)
