const role = (short, long) => ({ short, long })

export const gf = role('GF', 'Gruppenführer')
export const me = role('Me', 'Melder')
export const gfMe = role('GF/Me', 'Gruppenführer/Melder')
export const ma = role('Ma', 'Maschinist')
export const at = role('AT', 'Angriffstrupp')
export const atf = role('ATF', 'Angriffstruppführer')
export const atm = role('ATM', 'Angriffstruppmann')
export const wt = role('WT', 'Wassertrupp')
export const wtf = role('WTF', 'Wassertruppführer')
export const wtm = role('WTM', 'Wassertruppmann')
export const st = role('ST', 'Schlauchtrupp')
export const stf = role('STF', 'Schlauchtruppführer')
export const stm = role('STM', 'Schlauchtruppmann')
