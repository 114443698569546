import { useSnackbar } from "material-ui-snackbar-provider";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import useTimer from "../../../hooks/useTimer";
import * as actions from "../actions";
import TimerComponent from "../components/Timer";

export default function Timer(props) {
  const groupSize = useSelector((state) => state.settings.lspGroupSize);
  const {
    startTimer,
    resumeTimer,
    stopTimer,
    resetTimer,
    unresetTimer,
    time,
    active,
  } = useTimer((state) => state.speedExercise.timer, actions);
  const snackbar = useSnackbar();

  const handleToggleTimer = useCallback(() => {
    if (active) {
      stopTimer();
      snackbar.showMessage("Stoppuhr angehalten", "Rückgängig", () => {
        resumeTimer();
      });
    } else if (time > 0) {
      resetTimer();
      snackbar.showMessage("Stoppuhr zurückgesetzt", "Rückgängig", () => {
        unresetTimer();
      });
    } else {
      startTimer();
      snackbar.showMessage("Stoppuhr gestartet");
    }
  }, [
    active,
    resetTimer,
    resumeTimer,
    snackbar,
    startTimer,
    stopTimer,
    time,
    unresetTimer,
  ]);

  return (
    <TimerComponent
      {...props}
      seconds={Math.round(time / 1000)}
      maximumSeconds={groupSize === 9 ? 75 : 70}
      onClick={handleToggleTimer}
    />
  );
}
