import React from 'react'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import { formatLength } from './util'

const styles = (theme) => ({
  root: {
    padding: 8
  },
  paper: {
    padding: 16,
    marginBottom: 8,
    background: theme.palette.secondary.main,
    color: '#fff'
  },
  results: {
    width: '100%',
    fontSize: '15px',
    borderCollapse: 'collapse',
    '& tr > td:last-child': {
      textAlign: 'right'
    }
  },
  bold: {
    fontWeight: 700
  },
  spacing: {
    height: 8
  },
  spacingLine: {
    height: 8,
    '& > td': {
      borderBottom: '1px solid #fff'
    }
  }
})

function getPoints (distance, groupSize) {
  if (groupSize === 9) {
    if (distance > 70) {
      return 4
    }
    if (distance > 64) {
      return 3
    }
    if (distance > 59) {
      return 2
    }
    if (distance >= 55) {
      return 1
    }
    return 0
  } else if (groupSize === 6) {
    if (distance > 46) {
      return 4
    }
    if (distance > 42) {
      return 3
    }
    if (distance > 39) {
      return 2
    }
    if (distance >= 36) {
      return 1
    }
    return 0
  }
}

function Results (props) {
  const {
    classes,
    shots,
    groupSize,
    ...other
  } = props

  const totalDistance = shots.reduce((sum, { distance, invalid }) => invalid ? sum : sum + (parseFloat(distance) || 0), 0)

  return (
    <div {...other} className={classes.root}>
      <Paper className={classes.paper}>
        <table className={classes.results}>
          <tbody>
            <tr><td>Gesamtdistanz:</td><td colSpan={2}>{formatLength(totalDistance)} m</td></tr>
            <tr className={classes.spacing} />
            {shots.map(({ distance, invalid }, i) => (
              <tr key={i}>
                <td>Stoß {i + 1}</td>
                <td>{invalid ? 'ungültig' : ''}</td>
                <td>{distance && parseFloat(distance) ? `${formatLength(parseFloat(distance))} m` : '–'}</td>
              </tr>
            ))}
            <tr className={classes.spacingLine}><td colSpan={3} /></tr>
            <tr className={classes.spacing} />
            <tr className={classes.bold}><td colSpan={2}>Punkte:</td><td>{getPoints(totalDistance, groupSize)}</td></tr>
          </tbody>
        </table>
      </Paper>
    </div>
  )
}

export default withStyles(styles)(Results)
