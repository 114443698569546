import { INSTALL_SW, UPDATE_SW, SET_PAGE } from './actions'

export const serviceWorker = (state = {
  updated: false,
  installed: false
}, action) => {
  switch (action.type) {
    case INSTALL_SW:
      return { ...state, installed: true }
    case UPDATE_SW:
      return { ...state, updated: true }
    default:
      return state
  }
}

export const selectedPage = (state = localStorage.getItem('selectedPage') || 'PartA', action) => {
  switch (action.type) {
    case SET_PAGE:
      return action.payload
    default:
      return state
  }
}
