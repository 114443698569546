import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'

export default function MenuIconButton ({
  icon: Icon,
  children,
  ...other
}) {
  const [open, setOpen] = React.useState(false);
  const anchorEl = React.useRef();

  return (
    <React.Fragment>
      <IconButton
        {...other}
        buttonRef={anchorEl}
        onClick={() => setOpen(!open)}
      >
        <Icon />
      </IconButton>
      <Menu
        open={open}
        anchorEl={anchorEl.current}
        onClose={e => {
          if (!anchorEl.current.contains(e.target)) {
            setOpen(false);
          }
        }}
        onClick={() => setOpen(false)}
      >
        {children}
      </Menu>
    </React.Fragment>
  )
}
