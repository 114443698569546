import React from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from 'mdi-material-ui/ChevronDown'
import ErrorsTable from '../ErrorsTable'
import * as errors from '../../errors'

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  expansionPanelDetails: {
    paddingLeft: 0,
    paddingRight: 0
  }
})

class RunnerPanel extends React.Component {
  shouldComponentUpdate ({ sheet, checkedMistakes, waterSource, mistakes, ...other }) {
    // only update if there are new errors that affect our roles
    if (sheet !== this.props.sheet) return true
    if (waterSource !== this.props.waterSource) return true
    for (const role of errors[waterSource][sheet].roles) {
      if (checkedMistakes[role] !== this.props.checkedMistakes[role]) {
        return true
      }
    }

    // shallow-compare other props
    for (const key of Object.keys(other)) {
      if (this.props[key] !== other[key]) return true
    }

    return false
  }

  render () {
    const {
      classes,
      mistakes,
      checkedMistakes,
      onToggleMistake,
      sheet,
      title,
      hideClothErrors,
      hideFastCouplingErrors,
      waterSource,
      ...other
    } = this.props

    return (
      <ExpansionPanel {...other}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>{title}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionPanelDetails}>
          <ErrorsTable
            checkedMistakes={checkedMistakes}
            mistakes={mistakes}
            hideClothErrors={hideClothErrors}
            hideFastCouplingErrors={hideFastCouplingErrors}
            onToggleMistake={onToggleMistake}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

export default withStyles(styles)(RunnerPanel)
