import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import RunnerPanel from './RunnerPanel'
import errors from '../../errors'

const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingBottom: 24,
  },
  wrapper: {
    margin: 8,
    marginTop: 16
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expansionPanelDetails: {
    paddingLeft: 0,
    paddingRight: 0
  },
  results: {
    width: '100%',
    fontSize: '15px',
    borderCollapse: 'collapse',
    '& tr > td:first-child': {
      paddingLeft: 24
    },
    '& tr > td:last-child': {
      paddingRight: 24,
      textAlign: 'right'
    },
  },
  bold: {
    fontWeight: 700
  }
});

class RatingAccordion extends React.PureComponent {
  state = {
    expanded: null
  }

  handleExpand = (event, panel, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    })
  }

  render() {
    const { classes, mistakes, onToggleMistake, group, time, hideClothErrors, ...other } = this.props;
    const { expanded } = this.state;

    return (
      <div className={classes.root} {...other}>
        <div className={classes.wrapper}>
          {errors.map((runner, i) => (
            <RunnerPanel
              key={i}
              runner={i}
              expanded={expanded === i}
              mistakes={errors[i]}
              checkedMistakes={mistakes[i]}
              onChange={this.handleExpand}
              onToggleMistake={onToggleMistake}
              hideClothErrors={hideClothErrors}
            />
          ))}
        </div>
      </div>
    );
  }
}

RatingAccordion.propTypes = {
  mistakes: PropTypes.arrayOf(PropTypes.object).isRequired,
  onToggleMistake: PropTypes.func.isRequired,
  hideClothErrors: PropTypes.bool
}

export default withStyles(styles)(RatingAccordion)

export function getErrorPoints (runner, checkedMistakes) {
  let points = 0
  for (const error of errors[runner]) {
    if (!Number.isInteger(error.points)) {
      for (const subError of error.points) {
        if (checkedMistakes[subError.id]) {
          points += subError.points
        }
      }
    } else if (checkedMistakes[error.id]) {
      points += error.points
    }
  }
  return points
}

export function getTotalErrorPoints (mistakes) {
  return mistakes.reduce((sum, runnerMistakes, i) => sum + getErrorPoints(i, runnerMistakes), 0)
}
