import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Fab from '@material-ui/core/Fab'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import AddIcon from 'mdi-material-ui/Plus'
import DeleteIcon from 'mdi-material-ui/Delete'
import AddMemberDialog from './AddMemberDialog'
import { AppBarLayout, AppBar } from '../../AppFrame'

const styles = {
  root: {
    display: 'flex'
  },
  list: {
    flex: 1,
    paddingBottom: 72
  },
  helpText: {
    alignSelf: 'center',
    padding: '0 24px',
    margin: '0 auto',
    textAlign: 'center'
  },
  fab: {
    position: 'fixed',
    bottom: 16,
    right: 16
  }
}

class MembersList extends Component {
  state = {
    addMember: false
  }

  handleAddMember = () => {
    this.setState({ addMember: true })
  }

  handleSaveMember = (member) => {
    this.setState({ addMember: false })
    this.props.onAddMember(member)
  }

  handleCancelAddMember = () => {
    this.setState({ addMember: false })
  }

  render () {
    const {
      classes,
      members,
      onToggleIsInGroup,
      onDeleteMember,
      onAddMember, // eslint-disable-line
      onOpenDrawer,
      ...other
    } = this.props

    const {
      addMember
    } = this.state

    return (
      <AppBarLayout
        appBarRenderer={(props) => (
          <AppBar
            {...props}
            title='Übungsgruppe'
            onButtonClick={onOpenDrawer}
          />
        )}
      >
        <div {...other} className={classes.root}>
          {members.length > 0 && (
            <List className={classes.list}>
              {[...members].sort((a, b) => a.name.localeCompare(b.name)).map(({ id, name, yearOfBirth, isInGroup }) => (
                <ListItem
                  key={id}
                  role={undefined}
                  dense
                  button
                  onClick={() => onToggleIsInGroup(id)}
                >
                  <Checkbox
                    checked={isInGroup}
                    tabIndex={-1}
                    disableRipple
                  />
                  <ListItemText
                    primary={name}
                    secondary={`Jahrgang ${yearOfBirth}`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => onDeleteMember(id)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
          {members.length === 0 && (
            <Typography className={classes.helpText} component='div'>
              Hier kannst du die Jugendlichen hinzufügen und neun Mitglieder für die Übungsgruppe auswählen.<br /><br />
              Das Gesamtalter der Gruppe wird bei der Auswertung des B-Teils für die Zeitvorgabe berücksichtigt.
            </Typography>
          )}
          <Fab
            className={classes.fab}
            color='secondary'
            onClick={this.handleAddMember}
          >
            <AddIcon />
          </Fab>
          <AddMemberDialog
            open={addMember}
            onClose={this.handleCancelAddMember}
            onSubmit={this.handleSaveMember}
          />
        </div>
      </AppBarLayout>
    )
  }
}

MembersList.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    yearOfBirth: PropTypes.number.isRequired,
    isInGroup: PropTypes.bool.isRequired
  })).isRequired,
  onToggleIsInGroup: PropTypes.func.isRequired,
  onAddMember: PropTypes.func.isRequired,
  onDeleteMember: PropTypes.func.isRequired
}

export default withStyles(styles)(MembersList)
